import { Button, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SelectDiningArea from "components/shared/selectDiningArea/SelectDiningArea";
import React, { useEffect, useState } from "react";
import "./Residents.css";
import "../../../index.css";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import ResidentInfo from "./ResidentInfo";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectLastDiningArea, setFacilityDiningAreas, setLastDiningArea } from "store/slices/diningAreaSlice/DiningAreaSlice";
import { EditSkeleton } from "components/admin/admin.overlays";
import { useGetDiningAreasQuery } from "store/apis/DiningAreasApi";
import { useGetResidentActiveMealsByIdQuery, useGetResidentsAllQuery, useGetResidentsByAreaIdQuery } from "store/apis/ResidentsApi";

export default function Residents({heldOrders}) {
  const [open, setOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [selectedDiningAreaId, setSelectedDiningAreaId] = useState(0);
  const [selectedDiningAreaName, setSelectedDiningAreaName] = useState("");
  const [residents, setResidents] = useState([]);
  const [shownResidents, setShownResidents] = useState([]);
  const [selectedResidentIdForInfo, setSelectedResidentIdForInfo] = useState(0);
  const [selectedResidentIdForOrder, setSelectedResidentIdForOrder] = useState(0);
  const lastDiningArea = useSelector(selectLastDiningArea);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: diningAreas,
    isFetching: fetchingAreas,
  } = useGetDiningAreasQuery();

  useEffect(() => {
    dispatch(setFacilityDiningAreas(diningAreas));
    diningAreaSelected(lastDiningArea?.diningAreaId || diningAreas?.[0]?.id, lastDiningArea?.diningAreaName || diningAreas?.[0]?.name);
  }, [diningAreas]);

  const openSelectDiningArea = () => {
    setOpen(true);
  }

  const handleInfoClose = () => {
    setInfoOpen(false);
  }

  const diningAreaSelected = (diningAreaId, diningAreaName) => {
    setOpen(false);
    setSelectedDiningAreaId(diningAreaId);
    setSelectedDiningAreaName(diningAreaName);
    dispatch(setLastDiningArea({ diningAreaId: diningAreaId, diningAreaName: diningAreaName }))
  }

  const { data: diningAreaResidents } = useGetResidentsByAreaIdQuery( selectedDiningAreaId,
    { refetchOnMountOrArgChange: true, skip: !selectedDiningAreaId || selectedDiningAreaId === 'all' }
  );

  const { data: allResidents, isFetching: fetchingResidents } = useGetResidentsAllQuery({ refetchOnMountOrArgChange: true });

  useEffect(() => {
    setResidents(diningAreaResidents);
  }, [diningAreaResidents])

  const filterResidents = (list, searchString) => {
    return list?.filter((resident) => {
      return ((resident?.name?.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) || 
      (resident?.roomNumber?.toLowerCase()?.indexOf(searchString.toLowerCase()) !== -1));
    }); 
  }

  const searchResidents = (searchString) => {
    let filteredResidents = selectedDiningAreaId === 'all' ? filterResidents(allResidents, searchString) : filterResidents(residents, searchString);

    if (filteredResidents?.length === 0 && searchString === '') {
      filteredResidents =  selectedDiningAreaId === 'all' ? [...allResidents] : [...residents];
    }
    setShownResidents([...filteredResidents]);
  }

  const isResidentIsolated = (resident) => {
    return resident?.isIsolation ? `${resident.name} ( * Isolation )` : resident.name;
  }

  const { data: activeMeals } = useGetResidentActiveMealsByIdQuery(selectedResidentIdForOrder,
    { skip: !selectedResidentIdForOrder }
  );
  
  useEffect(() => {
    if (activeMeals) {
      takeResidentOrder(selectedResidentIdForOrder);
    }
  }, [activeMeals]);

  const takeResidentOrder = (residentId) => {
      if (activeMeals?.OnlyOneMeal) {
        navigate({
          pathname: `/takeorder/residents/order/${residentId}/${activeMeals.Days[0].Meals[0].SetupId}`,
          search: `?current=true`
        });
      } else {
        navigate(`/takeorder/residents/meals/${residentId}`)
      }
  }

  const onResidentInfoClick = (residentId) => {
    setSelectedResidentIdForInfo(residentId);
    setInfoOpen(true);
  }

  useEffect(() => {
    if (allResidents?.length && selectedDiningAreaId === 'all') {
      setShownResidents(() => [...allResidents]);
    } else if (residents?.length) {
      setShownResidents(() => [...residents]);
    }
  }, [residents, selectedDiningAreaId, allResidents]);

  const renderShownResidents = () => {
    const residentUIList = [];

    shownResidents?.forEach((resident) => {
      let hasHeldOrder = false;
      heldOrders?.forEach((heldOrder) => {
        if (heldOrder?.order?.residentId === resident.id) {
          hasHeldOrder = true;
        }
      });
      if (!hasHeldOrder) {
        residentUIList.push( 
          <ListItem
            key={resident.id} 
            style={{backgroundColor: resident.orderTaken ? 'lightgray' : 'white', cursor: "pointer"}} 
            onClick={() => setSelectedResidentIdForOrder(resident.id)}
          >
            <ListItemIcon> <PersonIcon style={{transform: "scale(1.5)"}} /> </ListItemIcon>
            <ListItemText primary={<span className="resident-name">{isResidentIsolated(resident)}</span>} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => onResidentInfoClick(resident.id)}>
                <InfoIcon style={{transform: "scale(1.2)"}} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      }
    });

    return residentUIList;
  }
  
  return (
    <>
      {fetchingAreas || fetchingResidents ? (
        <EditSkeleton />
      ) : (
        <div className="paperContent customScrollbar" style={{backgroundColor: "#FFF"}}>
          <Grid container justifyContent="center" sx={{marginBottom: ".5rem"}}>
            <Button 
              id="dining-btn"
              fullWidth 
              variant="contained" 
              onClick={openSelectDiningArea}
              sx={{color: "rgba(0, 0, 0, .87", backgroundColor: "#e0e0e0 !important"}}
            >
              Dining Area: {selectedDiningAreaName}
            </Button>
            <SelectDiningArea
              open={open} 
              diningAreas={diningAreas}  
              itemSelected={diningAreaSelected}
              title="Select Dining Area"
              enableAll={true}
              />
          </Grid>  

          <Grid container >
            <TextField
              fullWidth
              variant="standard"
              type="search"
              onChange={(event) => searchResidents(event.target.value)}
              placeholder="Search by Name, Room Number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            >
            </TextField>  
          </Grid>  
          <List>
            {renderShownResidents()}
          </List>
          <ResidentInfo
            open={infoOpen}
            handleInfoClose={handleInfoClose}
            residentId={selectedResidentIdForInfo}
          />
        </div>
      )}
    </>
  )
}