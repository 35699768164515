import { Dialog, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { EditSkeleton } from "components/admin/admin.overlays";
import React from "react";
import { useGetResidentInfoByIdQuery } from "store/apis/ResidentsApi";

export default function ResidentInfo({ open, handleInfoClose, residentId }) {
  
  const {
    data: residentInfo,
    isFetching: fetchingResident,
  } = useGetResidentInfoByIdQuery(
    residentId,
    { refetchOnMountOrArgChange: true, skip: !residentId}
  );

  return (
    <Dialog
      open={open}
      onClose={handleInfoClose}
      PaperProps={{
        sx: { width: "45%", 
              height: "auto", 
              minWidth: "400px",
              padding: "2rem"}
      }}
    >
       { fetchingResident ? (
          <EditSkeleton />
        ) : 
        (
          <>
          
            <Typography variant="h5">
              {residentInfo && residentInfo.name}
            </Typography>
            <Table sx={{fontSize: ".8125rem"}}>
              <TableBody>
                <TableRow>
                  <TableCell>Diet</TableCell>
                  <TableCell>{ residentInfo && residentInfo.diet }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Diet Texture</TableCell>
                  <TableCell>{ residentInfo && residentInfo.dietTexture }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dislikes</TableCell>
                  <TableCell>{ residentInfo && residentInfo.dislikes }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Allergies</TableCell>
                  <TableCell>{ residentInfo && residentInfo.allergies }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Fluid Restriction</TableCell>
                  <TableCell>{ residentInfo && residentInfo.fluidRestrictions }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dining Area</TableCell>
                  <TableCell>{ residentInfo && residentInfo.diningArea }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Room</TableCell>
                  <TableCell>{ residentInfo && residentInfo.roomNumber }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Notes</TableCell>
                  <TableCell>{ residentInfo && residentInfo.notes }</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
    </Dialog>
  )
}