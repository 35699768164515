import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { ACCOUNT } = API_ENDPOINTS;

export const AccountApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPermissions: builder.query({
      query: () => `/${ACCOUNT}/Permissions`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response 
        ? [
          { type: ACCOUNT, id: response }
        ]
        : [ACCOUNT]
      }
    }),
  })
});

export const {
  useGetPermissionsQuery,
} = AccountApi;