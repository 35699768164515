import { AppBar, Dialog, Divider, IconButton, List, ListItemButton, ListItemText, Slide, Toolbar, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectResident({ open, residents, itemSelected, title}) {

  const handleClose = () => {
    itemSelected();
  }

  const renderResidents = () => {
    const uiArray = [];

    residents?.forEach((area, idx) => {
      uiArray.push(
        <ListItemButton key={`list${area.id}`} onClick={() => itemSelected(area.id, area.name)}>
          {/* Remove nicknames from list if present, legacy did not show them */}
          <ListItemText fontWeight="bold" primary={area?.name?.split("(")?.at(0)?.trim()} />
        </ListItemButton>
      );

      if (idx < residents?.length - 1) {
        uiArray.push(<Divider key={`divider${area.id}`} />)
      }
    })

    return uiArray;
  } 

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
        <AppBar position="static">
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" fontWeight="bold" color="inherit" sx={{marginLeft: ".25rem"}}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {renderResidents()}
        </List>

      </Dialog>
  );
}