import { Button, Card, CardContent, List, ListItem, MenuItem, Typography } from "@mui/material";
import { EditSkeleton } from "components/admin/admin.overlays";
import InfoModal from "components/shared/infoModal/InfoModal";
import React, { useEffect, useState } from "react";
import { useGetAvailableMealsQuery, useInsertMealMutation } from "store/apis/MealsApi";
import PendingMeals from "./PendingMeals";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./StartMeal.css";
import { useForm } from "react-hook-form";
import BaseCheckBox from "components/shared/baseForm/BaseCheckBox";
import BaseSelect from "components/shared/baseForm/BaseSelect";
import dayjs from "dayjs";

export default function StartMeal() {

  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [mealOptions, setMealOptions] = useState([]);
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [menuOptions, setMenuOptions] = useState([]);
  const [dayOptions, setDayOptions] = useState([]);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: {
      day: '',
      mealId: '',
      menuId: '',
      seasonId: '',
      diningDate: new Date(),
      isNow: true
    }
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: {errors}
  } = methods;

  const handleInfoModalClose = () => {
    setOpen(false)
  }

  const {
    data: availableMeals,
    isFetching: fetchingMeals,
    isSuccess: successMeals,
    isError: errorMeals
  } = useGetAvailableMealsQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (errorMeals && !fetchingMeals) {
      setModalTitle("Alert");
      setModalText("TableSide setup has not been done for this facility.\n" +
        "Please go to the Dining Manager website to fix this issue."
      );
      setOpen(true);
    } else if (successMeals && !fetchingMeals && availableMeals) {

      setMealOptions(() => availableMeals?.meals?.map((meal) => {
        return { id: meal.id, label: meal.name }
      }));

      setSeasonOptions(() => availableMeals?.seasons?.map((season) => {
        return { id: season.id, label: season.name }
      }));

      setMenuOptions(() => availableMeals?.seasons?.[0]?.menus?.map((menu) => {
        return { id: menu.id, label: menu.name}
      }));
      const daysInDefaultMenu = availableMeals?.seasons?.[0]?.menus?.[0]?.daysInMenu;
      setDayOptions(() => [...Array(daysInDefaultMenu).keys()].map(i => {
        return {id: ++i, label: `${i}`}
      }));

      setValue('mealId', availableMeals?.meals?.[0]?.id);
      setValue('seasonId', availableMeals?.seasons?.[0]?.id);
      setValue('menuId', availableMeals?.seasons?.[0]?.menus?.[0]?.id);
      setValue('day', 1);
    }
  }, [errorMeals, fetchingMeals, successMeals, availableMeals]);

  const useCurrentDateTime = watch('isNow', true);

  const [ addMeal ] = useInsertMealMutation();

  const handleStartMeal = (data) => {
    //we dont really care how many time user clicks "Now" checkbox
    //final state dictates if we use form value or reset to new Date()
    if (useCurrentDateTime) {
      data.diningDate = new Date();
    }

    // make UTC adjustments for DB write from payload
    const hours = data.diningDate.getHours();
    const minutes = data.diningDate.getMinutes();

    data.diningDate?.setUTCHours(hours, minutes, 0, 0);

    addMeal(data);
  }

  const renderMeals = () => {
    const mealList = [];
    availableMeals?.meals?.forEach((meal) => {
      mealList.push(
        <MenuItem key={meal.id} value={meal.id}>{meal.name}</MenuItem>
      );
    });

    return mealList;
  }

  const renderSeasons = () => {
    const seasonList = [];
    availableMeals?.seasons?.forEach((season) => {
      seasonList.push(
        <MenuItem key={season.id} value={season}>{season.name}</MenuItem>
      );
    });

    return seasonList;
  }

  const handleSeasonChange = (season) => {
    // check if same menu exists in new season selected, 
    // should but we need the payload references anyway for other calculations
    const newSeason = availableMeals?.seasons?.find((s) => s.id === season.id);
    const newSeasonMenu = newSeason?.menus?.find((menu) => menu?.id === getValues()?.menuId);

    setMenuOptions(() => newSeason?.menus?.map((menu) => {
      return { id: menu.id, label: menu.name}
    }));

    if (!newSeasonMenu) {
      setValue('menuId', newSeason?.menus?.[0]?.id);
      if (newSeason?.menus?.[0]?.daysInMenu < getValues()?.day) {
        setValue('day', 1);
      }
    }

    setValue('seasonId', season.id);
  }

  const renderMenus = () => {
    const menuList = [];

    if (getValues()?.season) {
      getValues()?.season.menus?.forEach((menu) => {
        menuList.push(
          <MenuItem key={menu.id} value={menu}>{menu.name}</MenuItem>
        );
      });
    }

    return menuList;
  }

  const handleMenuChange = (menu) => {
    // when changed menu day range falls short of previously selected day, reset
    const selectedMenu = availableMeals?.seasons?.find((season) => season.id === getValues()?.seasonId)?.menus?.find((m) => m.id === menu.id);

    setDayOptions(() => [...Array(selectedMenu.daysInMenu).keys()].map(i => {
      return {id: ++i, label: `${i}`}
    }));    

    if (selectedMenu?.daysInMenu < getValues()?.day) {
      setValue('day', 1);
    }

    setValue('menuId', menu.id);
  }

  const renderDays = () => {
    const dayList = [];
    const numberOfDays = getValues()?.menu?.daysInMenu;

    if (numberOfDays > 0) {
      for (let i = 1; i <= numberOfDays; i++) {
        dayList.push(
          <MenuItem key={i} value={i}>{i}</MenuItem>
        );
      }
    }

    return dayList;
  }

  return (
    <> {
      fetchingMeals ? (
        <EditSkeleton />
      )
      : 
      (
        <div className="mealContainer customScrollbar">
          <Card sx={{margin: "1rem", height: "inherit", paddingBottom: "3rem"}}>
            <CardContent>
              <Typography variant="h5" gutterBottom>Start a Meal</Typography>
              <List sx={{paddingLeft: "1rem"}}>
                <ListItem>
                  <Typography variant="subtitle1">Meal</Typography>
                </ListItem>
                <ListItem>
                  <BaseSelect
                    id={"mealId"}
                    name={"mealId"}
                    control={control}
                    errors={errors}
                    fullWidth
                    size="small"
                    options={mealOptions}
                    disableClearable={true}
                  >
                    {renderMeals()}
                  </BaseSelect>
                </ListItem>

                <ListItem>
                  <Typography variant="subtitle1">Season</Typography>
                </ListItem>
                <ListItem>
                  <BaseSelect
                    id={"seasonId"}
                    name={"seasonId"}
                    fullWidth
                    control={control}
                    options={seasonOptions}
                    onChange={(event, value) => handleSeasonChange(value)}
                    disableClearable={true}
                    size="small"
                  >
                    {renderSeasons()}
                  </BaseSelect>
                </ListItem>

                <ListItem>
                  <Typography variant="subtitle1">Menu</Typography>
                </ListItem>
                <ListItem>
                  {/* Menu Select */}
                  <BaseSelect
                    id={"menuId"}
                    name={"menuId"}
                    fullWidth
                    control={control}
                    errors={errors}
                    options={menuOptions}
                    disableClearable={true}
                    size="small"
                    onChange={(event, value) => handleMenuChange(value)}
                  >
                    {renderMenus()}
                  </BaseSelect>
                </ListItem>

                <ListItem>
                  <Typography variant="subtitle1">Menu Day</Typography>
                </ListItem>
                <ListItem>
                  <BaseSelect
                    id={"day"}
                    name={"day"}
                    fullWidth
                    control={control}
                    errors={errors}
                    options={dayOptions}
                    disableClearable={true}
                    size="small"
                  >
                    {renderDays()}
                  </BaseSelect>
                </ListItem>

                <ListItem>
                  <Typography variant="subtitle1">Dining Date and Time</Typography>
                </ListItem>
                <ListItem sx={{flexWrap: "wrap"}}>
                  <BaseCheckBox
                    control={control}
                    label={"Now"}
                    name={"isNow"}
                    errors={errors}
                  />  
                  {!useCurrentDateTime && 
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDateTimePicker 
                        sx={{marginTop: "1rem"}} 
                        label="Select Date / Time" 
                        minutesStep={15}
                        closeOnSelect={false}
                        ampmInClock={true}
                        defaultValue={dayjs(new Date().setHours(0, 0, 0, 0))}
                        onChange={(value) => setValue('diningDate', new Date(value))}
                      />
                    </LocalizationProvider>
                    }
                </ListItem>

              </List>
                <Button 
                  variant="contained" 
                  sx={{width: "-webkit-fill-available", margin: "1rem 2rem"}} 
                  onClick={handleSubmit(handleStartMeal)}
                >
                  Start a Meal
                </Button>
            </CardContent>
            {open && <InfoModal open={open} title={modalTitle} modalText={modalText} handleClose={handleInfoModalClose} />}
          </Card>
          <PendingMeals />
        </div>
      )
    }
    </>
  );
}