import { MenuItem, Select } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_FIELD, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import { CustomNoRowsOverlay } from "components/admin/admin.overlays";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useGetFutureOrderQuery, useGetSubmittedOrderByIdQuery, useGetSubmittedOrdersQuery } from "store/apis/OrdersApi";
import { useGetResidentsAllQuery } from "store/apis/ResidentsApi";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import "./Submitted.css";
import DeleteModal from "components/shared/deleteModal/DeleteModal";
import { OrderDetailContent } from "./OrderDetailContent";
import { useDispatch } from "react-redux";
import { setMenuInfo } from "store/slices/orderSlice/OrderSlice";

export default function Submitted() {
  const gridRef = useGridApiRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mealSelection, setMealSelection] = useState("All");
  const [groupBySubmittedOrders, setGroupBySubmittedOrders] = useState("")
  const [lastSelectedRow, setLastSelectedRow] = useState(null);
  const [lastSelectedOrderId, setLastSelectedOrderId] = useState(null);
  const [submittedOrderById, setSubmittedOrderById] = useState(null);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedForDeleteId, setSelectedForDeleteId] = useState(null);
  const [futureOrderId, setFutureOrderId] = useState(null); // for retrieving full order info for edits

  const fetchForEdit = async (params) => {
    const parentRow = allSubmittedOrders?.find((submitted) => submitted.orderId === params?.row?.orderId);
    setFutureOrderId(parentRow.orderId);
  }

  const displayDeleteOrderConfirmModal = (orderId) => {
    setSelectedForDeleteId(orderId);
    setShowDeleteModal(true);
  }

  const columns = [
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      headerName: '',
      minWidth: 1,
      maxWidth: 1,
      disableColumnMenu: true
    },
    { field: 'name', headerName: 'Full Name', flex: .5, minWidth: 100 },
    { field: 'date', headerName: 'Meal Date', flex: .5, minWidth: 150, headerAlign: 'left', align: 'left' },
    { field: 'meal', headerName: 'Meal Name', flex: .3, minWidth: 100, headerAlign: 'left', align: 'left' },
    {
      field: 'actions',
      type: 'actions',
      minWidth: 150,
      align: 'left',
      renderCell: (params) => {
        if (!params?.row?.isActive) {
          return [
            <GridActionsCellItem 
              key={"1"}
              icon={<DeleteIcon />}
              label='Delete'
              onClick={() => displayDeleteOrderConfirmModal(params.row.orderId)}
            />,
            <GridActionsCellItem 
              key={"2"}
              sx={{marginLeft: '1rem'}}
              icon={<EditIcon />}
              label='Edit'
              onClick={() => fetchForEdit(params)}
            />
          ]
        }
      },
    }
  ];

  const {
    data: residents,
    isFetching: fetchingResidents
  } = useGetResidentsAllQuery();

  const {
    data: allSubmittedOrders,
    isFetching: fetchingOrders,
    refetch: fetchOrders
  } = useGetSubmittedOrdersQuery(
    { refetchOnMountOrArgChange: true,  skip: !residents?.length }
  );

  const isFetching = fetchingResidents || fetchingOrders;

  useEffect(() => {
      if (allSubmittedOrders) {
        if (mealSelection === 'All') {
          setGroupBySubmittedOrders(() => allSubmittedOrders ? [...allSubmittedOrders] : []);
        } else {
          handleMealChange(mealSelection);
        }
      }
  }, [allSubmittedOrders]);

  const { data: submittedOrder } = useGetSubmittedOrderByIdQuery(lastSelectedOrderId, 
    {refetchOnMountOrArgChange: true, skip: !lastSelectedOrderId || lastSelectedOrderId <= 0}
  );

  useEffect(() => {
    if (submittedOrder && submittedOrder?.orderId) {
      setSubmittedOrderById(() => submittedOrder);
    }
  }, [submittedOrder])

  const {data: futureOrder } = useGetFutureOrderQuery(futureOrderId, 
    { refetchOnMountOrArgChange: true, skip: !futureOrderId }
  );

  useEffect(() => {
    //used for when edit is clicked and full order details are finished loading
    if (futureOrder && futureOrder?.order?.id) {

      dispatch(setMenuInfo(futureOrder));

      // bifurcate navigation based on guest or not
      // guests dont actually get to place future orders currently (pre-req for edit button visibility)
      // just leaving since it was present in legacy after discussing with Tim
      if (futureOrder.order?.guest?.length) { // if guest name length, always "" or null for residents
        navigate({
          pathname: `/takeorder/guest`,
          search: `?from=submitted`
        });
      } else {
        navigate({
          pathname: `/takeorder/residents/order/${futureOrder.order.residentId}/${futureOrder.order.tableSideSetupId}`,
          search: `?current=true&from=submitted`
        });
      }
    }
  }, [futureOrder])

  const handleMealChange = (meal) => {
    setMealSelection(meal);

    if (meal === 'All') {
      setGroupBySubmittedOrders(allSubmittedOrders);
      return;
    }

    let meals = _.groupBy(allSubmittedOrders, (item) => `${item.mealName} -- ${item.mealDate}`);
    const results = Object.entries(meals);

    results.forEach((res) => {
      if (res[0] === meal) {
        setGroupBySubmittedOrders(res[1]);
        return;
      }
    });
  }  

  const handleSubmittedOrderClick = (rowItem) => {
    setLastSelectedOrderId(rowItem?.orderId);
  }

  const renderMealOptions = () => {
    let meals = _.groupBy(allSubmittedOrders, (item) => `${item.mealName} -- ${item.mealDate}`);
    const results = Object.entries(meals);
    // if user was filtering on by meal and deleted last meal while not set to 'All', 
    // we need to force a return to 'All'

    if (mealSelection !== 'All' && !Object.values(results).some((list) => list[0] === mealSelection)) {
      //need to reset to all
      handleMealChange('All');
    }
    const mealUIList = [<MenuItem key={'All'} value={'All'}>{'All'}</MenuItem>]

    results.forEach((item) => {
      mealUIList.push(
        <MenuItem key={item[0]} value={item[0]}>{item[0]}</MenuItem>
      );
    });

    return mealUIList;
  }

  const getDetailPanelContent = () =>  <OrderDetailContent
                    lastSelectedRow={lastSelectedRow}
                    groupBySubmittedOrders={groupBySubmittedOrders}
                    submittedOrderById={submittedOrderById}
                    updateOrders={fetchOrders}
                  />;

  const renderGrid = () => {
    const subOrders = groupBySubmittedOrders || [];

    if (!subOrders?.length) { 
      if (mealSelection !== 'All') {
        handleMealChange('All');
      }
    } // display when [] still

    let rows = [];
    
    subOrders.forEach((order, idx) => {
      rows.push({
        id: `${idx}-${order.orderId}`,
        residentId: order.id,
        orderId: order.orderId,
        name: `${order.firstName} ${order.lastName}`,
        date: order.mealDate,
        meal: order.mealName,
        isActive: order.isActiveOrder
      });
    });

    return (
      <DataGridPro 
        sx={{ '.MuiDataGrid-row:hover': { cursor: "pointer" }, height: "100%" }}
        rows={rows}
        apiRef={gridRef}
        columns={columns}
        loading={isFetching}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay
        }}
        getDetailPanelContent={() => getDetailPanelContent()}
        getDetailPanelHeight={() => "auto"}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        rowHeight={30}
        onRowClick={async (params) => {
          setDetailPanelExpandedRowIds(detailPanelExpandedRowIds?.at(0) === params.id ? [] : [params.id]);
          setLastSelectedRow(params.row);
          await handleSubmittedOrderClick(params.row);
        }}
      />
    );
  }

  return (
    <div className="paperContent customScrollbar">
      <Grid container justifyContent="center" alignItems="center" sx={{marginBottom: ".5rem"}}>
        <label>Select Meal Options</label>
        <Select
          sx={{marginLeft: "1rem"}}
          variant="standard" 
          value={mealSelection} 
          onChange={(event) => handleMealChange(event?.target?.value)}>
          { renderMealOptions() }
        </Select>
      </Grid>
      <div style={{height: "90%", width: "100%"}}>
        { renderGrid() }
      </div>
      {showDeleteModal && <DeleteModal
        id={selectedForDeleteId}
        entityName={"this order"}
        apiPath={"deleteFutureOrder"}
        open={showDeleteModal}
        isafterSuccessFunction={true}
        afterSuccessFunction={() => {
          setShowDeleteModal(false);
        }}
        close={() => setShowDeleteModal(() => false)}
      />}
    </div>
  )
}