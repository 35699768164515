import { Box, Button, Card, CardContent, Dialog, Slide, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderConfirmModal({ open, orderData, selectedSubRecipes, confirmType, handleClose }) {

  const guestPrice = orderData?.guestPrice || 0.00;
  const addlCharges = orderData?.order?.additionalCharges || 0.00;
  const menuPriceString = `$${(guestPrice + addlCharges).toFixed(2)}`;

  const renderMenuItems = () => {
    const menuItems = [];

    orderData.menuItems?.forEach((item, idx) => {
      const menuChoice = orderData.menuChoices?.find((choice) => choice.recipeId === item.itemId);
      const subRecipes = selectedSubRecipes?.filter((sub) => sub.parentRecipeId === item.itemId) || [];
      menuItems.push(
        <TableRow key={`${item.itemId}-${idx}`} sx={{display: "grid", gridTemplateColumns: "7fr 1fr", width: "100%", borderBottom: "1px solid rgba(224,224,224,.85)"}}>  
          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{menuChoice?.recipeName}</Typography>
          </TableCell>
          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{item.quantity}</Typography>
          </TableCell>
        </TableRow>
      );

      subRecipes.forEach((sub, idx) => {
        menuItems.push(
          <TableRow key={`${sub.recipeId}-${idx}`} sx={{display: "grid", gridTemplateColumns: "7fr 1fr", width: "100%", borderBottom: "1px solid rgba(224,224,224,.85)"}}>
            <TableCell sx={{alignSelf: "center", paddingLeft: "4rem !important", borderBottom: "none"}}>
              <Typography variant="body1" gutterBottom>{sub?.recipeName}</Typography>
            </TableCell>
            <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
              <Typography variant="body1" gutterBottom>{sub?.quantity}</Typography>
            </TableCell>
          </TableRow>
        );
      })
    });

    return menuItems;
  }

  const renderAltItems = () => {
    const altItems = [];

    orderData.alternateItems?.forEach((item, idx) => {
      const menuChoice = orderData.alternateChoices?.find((choice) => choice.recipeId === item.itemId);
      const subRecipes = selectedSubRecipes?.filter((sub) => sub.parentRecipeId === item.itemId) || [];
      altItems.push(
        <TableRow key={`${item.itemId}-${idx}`} sx={{display: "grid", gridTemplateColumns: "7fr 1fr", width: "100%", borderBottom: "1px solid rgba(224,224,224,.85)"}}>
          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{menuChoice?.recipeName}</Typography>
          </TableCell>
          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{item?.quantity}</Typography>
          </TableCell>
        </TableRow>
      );

      subRecipes.forEach((sub, idx) => {
        altItems.push(
          <TableRow key={`${sub.recipeId}-${idx}`} sx={{display: "grid", gridTemplateColumns: "7fr 1fr", width: "100%", borderBottom: "1px solid rgba(224,224,224,.85)"}}>
            <TableCell sx={{alignSelf: "center", paddingLeft: "4rem !important", borderBottom: "none"}}>
              <Typography variant="body1" gutterBottom>{sub?.recipeName}</Typography>
            </TableCell>
            <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
              <Typography variant="body1" gutterBottom>{sub?.quantity}</Typography>
            </TableCell>
          </TableRow>
        );
      })
    });

    return altItems;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box sx={{maxWidth: "450px", height: "auto", maxHeight: "calc(100vh - 70px) !important"}} className="paperContent customScrollbar" justifyContent="center" alignItems="flex-start" padding={3}>
        <Typography variant="h6" gutterBottom textAlign="center">Are You Sure?</Typography>
        {!!orderData?.menuItems?.length && <Card sx={{marginBottom: "1rem"}}>
          <CardContent>
            <Typography variant="h5" gutterBottom textAlign="left">Menu Items</Typography>
            <Table>
              <TableBody>
                {renderMenuItems()}
              </TableBody>
            </Table>
          </CardContent>
        </Card>}

        {!!orderData?.alternateItems?.length && <Card sx={{marginBottom: "1rem"}}>
          <CardContent>
            <Typography variant="h5" gutterBottom textAlign="left">Alternate Items</Typography>
            <Table>
              <TableBody>
                {renderAltItems()}
              </TableBody>
            </Table>
          </CardContent>
        </Card>}

        {orderData?.order?.note && (
          <Card sx={{marginBottom: "1rem"}}>
            <CardContent>
              <Typography width="100%" variant="h5" textAlign="left">Note</Typography>
              <Grid sx={{display: "grid", gridTemplateColumns: "5fr 1fr"}} justifyContent="space-between" paddingX={1}>
                <Typography variant="body1">{orderData.order.note}</Typography>
                <Typography variant="body1">{`$${addlCharges.toFixed(2)}`}</Typography>
              </Grid>
            </CardContent>
          </Card>
        )}

        <Card>
          <CardContent>
            <Grid container justifyContent="space-between" paddingX={1} sx={{minWidth: "15rem"}}>
              <Typography variant="h5">Meal Price</Typography>
              <Typography variant="h5">{menuPriceString}</Typography>
            </Grid>
          </CardContent>
        </Card>

        <Grid container justifyContent="center" paddingY={3} paddingX={1}>
          <Button
            sx={{marginRight: ".5rem", width: "calc(50% - .5rem)"}}
            variant="contained"
            onClick={() => handleClose(true)}
          >
            {confirmType}
          </Button>
          <Button
            sx={{width: "calc(50% - .5rem)"}}
            variant="contained"
            onClick={() => handleClose(false)}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </Dialog>
  )
}