import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Box, styled } from "@mui/system";
import React from "react";
import Fade from "@mui/material/Fade";

const ErrorContainer = styled(Box)(() => ({
  marginTop: "75px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "15px",
  padding: "20px",
}));

const ErrorTitle = styled(Typography)({
  fontWeight: "bold",
  marginTop: "20px",
});

const ErrorMessage = styled(Typography)({
  marginTop: "10px",
});

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/ErrorPage.md)
 */
export default function ErrorPage({ message, title, icon, code, color }) {
  return (
    <Fade in={true} timeout={500}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center">
        <ErrorContainer className={`errorContainer-${color}`}>
          <Typography
            sx={{ marginBottom: "10px" }}
            color="error"
            textAlign="center"
            variant="h2">
            Something Went Wrong!
          </Typography>
          <Typography color="#000000" textAlign="center" variant="h5">
            We’re sorry. We’re experiencing some difficulties.
          </Typography>
          <Typography color="#000000" textAlign="center" variant="h6">
            Please refresh your browser.{" "}
          </Typography>
          {icon}
          <ErrorTitle color={color}>{code}</ErrorTitle>
          <Typography>{title}</Typography>
          <ErrorMessage>{message}</ErrorMessage>
        </ErrorContainer>
      </Grid>
    </Fade>
  );
}
