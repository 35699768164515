import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mealName: "",
  guestInfo: {
    guestName: "",
    paymentDone: null, // 0,1,2,3 for No Charge/Payment Received, Bill to Resident, Bill to Employee
    selectedResident: null, //residentID to tie order to
    selectedGuestType: null // 1, 2, 3 for Guest/Employee/Other respectively
  },
  menuInfo: null
}

export const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setSelectedMeal: (state, action) => {
      state.mealName = action.payload;
    },
    setGuestInfo: (state, action) => {
      state.guestInfo = {...action.payload};
    },
    //for storage and retrieval on held/submitted order edits
    setMenuInfo: (state, action) => {
      state.menuInfo = {...action.payload}
    }
  }
})

export const { 
  setSelectedMeal, 
  setGuestInfo, 
  setMenuInfo 
} = OrderSlice.actions;
export const selectActiveMeals = (state) => state?.order?.activeMeals;
export const selectResidentInfo = (state) => state?.order?.residentInfo;
export const selectMealName = (state) => state?.order?.mealName;
export const selectMenuInfo = (state) => state?.order?.menuInfo;
export const selectGuestInfo = (state) => state?.order?.guestInfo;

export default OrderSlice.reducer;