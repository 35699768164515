import React from "react";
import { createRoot } from "react-dom/client";
import "./styles/global.css";
import "./styles/overrides.css";
import App from "./App";
import { store } from "./store/configureStore";
import { Provider } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import { CircularProgress } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize().then(() => {
  const container = document.getElementById("root");
  const root = createRoot(container);
  let persistor = persistStore(store);

  root.render(
    <Provider store={store}>
      <PersistGate loading={<CircularProgress />} persistor={persistor}>
        <DndProvider backend={HTML5Backend}>
          <App pca={msalInstance} />
        </DndProvider>
      </PersistGate>
    </Provider>
  );
});

