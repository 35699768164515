import { Button, Card, CardContent, Typography } from "@mui/material";
import { EditSkeleton } from "components/admin/admin.overlays";
import BaseCheckBox from "components/shared/baseForm/BaseCheckBox";
import BaseInput from "components/shared/baseForm/BaseInput";
import ConfirmModal from "components/shared/confirmModal/ConfirmModal";
import InfoModal from "components/shared/infoModal/InfoModal";
import SelectDiningArea from "components/shared/selectDiningArea/SelectDiningArea";
import SelectResident from "components/shared/selectResident/SelectResident";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useGetDiningAreasQuery } from "store/apis/DiningAreasApi";
import { useAddCooksTicketMutation } from "store/apis/OrdersApi";
import { useGetResidentsAllQuery } from "store/apis/ResidentsApi";
import { DIRECT_PRINT_DATE_OPTIONS } from "store/store.constants";

//Cannot use CSS className for print output, must use inline styles
const printStyles = {
  printGeneral: { 
      paddingBottom: '5px', 
      paddingTop: '5px',
      fontSize: '15px'
  },
  printText: {
      paddingBottom: '15px', 
      paddingTop: '20px',
      fontSize: '15px'
  }
}

export default function CooksTicket({isDirectPrint, handlePrint}) {
  //only for showing text in button
  const [selectedResidentText, setSelectedResidentText] = useState(null);
  const [selectedDiningAreaText, setSelectedDiningAreaText] = useState(null);

  const [residentOpen, setResidentOpen] = useState(false);
  const [diningOpen, setDiningOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  const methods = useForm({
    shouldUnregister: false,
    mode: "all"
  });

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const {
    data: diningAreas,
    isFetching: fetchingAreas
  } = useGetDiningAreasQuery();

  const {
    data: residents,
    isFetching: fetchingResidents
  } = useGetResidentsAllQuery();

  const [
    addCooksTicket,
    {
      isLoading: savingTicket,
      isSuccess: savingTicketSuccess
    }
  ] = useAddCooksTicketMutation();

  useEffect(() => {
    if (!savingTicket && savingTicketSuccess) {
      if (isDirectPrint) {
        const html = document.getElementById('printSection').cloneNode(true);
        handlePrint(html);
      }

      reset();
      setSelectedResidentText("");
      setSelectedDiningAreaText("");
    }
  }, [savingTicket, savingTicketSuccess])

  const submitTicket = (data) => {
    data.ticketText = data.ticketText || '';
    addCooksTicket(data);
  }

  const isLoading = fetchingAreas || fetchingResidents;

  const handleResidentChange = (residentId) => {
    if (!residentId) { 
      setResidentOpen(false);
      return; 
    }


    const resident = residents.find((res) => res.id === residentId) ?? null;
    // attempt to set dining area for user based on resident's configuration
    const defaultDiningAreaId = resident.lunchDiningAreaId ?? resident.dinnerDiningAreaId ?? resident.breakfastDiningAreaId ?? null;
    const defaultDiningArea = diningAreas.find((din) => din.id === defaultDiningAreaId) ?? null;

    setResidentOpen(false);
    setSelectedResidentText(resident?.name?.split("(")?.at(0)?.trim());
    setValue('residentId', resident.id);
    setSelectedDiningAreaText(defaultDiningArea?.name);
    setValue('diningAreaId', defaultDiningAreaId);
  }

  const handleDiningChange = (diningAreaId) => {
    if (!diningAreaId) {
      setDiningOpen(false);
      return;
    }

    setDiningOpen(false);
    setSelectedDiningAreaText(diningAreas.find((din) => din.id === diningAreaId)?.name ?? null);
    setValue("diningAreaId", diningAreaId);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  }

  const handleConfirmClose = (shouldContinue) => {
    if (shouldContinue) {
      submitTicket(getValues());
      setConfirmOpen(false);
    } else {
      setConfirmOpen(false);
    }
  }

  const handleSubmitClick = (data) => {

    if (!data?.residentId || !data?.diningAreaId) {
      setModalText("Resident and Dining Area must be selected.");
      setModalOpen(true);
      return;
    }

    setConfirmText("Are you sure you want to submit this Cook's Ticket?");
    setConfirmOpen(true);
  }

  //Markup for direct print 
  const renderPrintContent = () => {
    const formData = getValues();

    return (
      <div id="printSection" style={{position: "absolute", top: "-9999px"}}>
        <div style={{padding: "10px 5px 5px 5px", fontSize: "1rem"}}>
          {
            (formData?.missingItem || formData?.managerRequired || formData?.additionalOrder) && 
            <div style={printStyles.printGeneral}>
              <div style={{ fontSize: "24px", fontWeight: "bold", paddingBottom: "5px", paddingTop: "5px"}}>
                {"Cook's Ticket"}
              </div>
              { formData?.missingItem && <div style={printStyles.printGeneral}>{'Missing Item'}</div> }
              { formData?.managerRequired && <div style={printStyles.printGeneral}>{'Manager Required'}</div> }
              { formData?.additionalOrder && <div style={printStyles.printGeneral}>{'Additional Order'}</div> }
            </div>
          }
          {
            formData?.residentId && <div style={printStyles.printGeneral}>{selectedResidentText}</div>
          }
          {
            formData?.diningAreaId && <div style={printStyles.printGeneral}>{selectedDiningAreaText}</div>
          }
          {
            formData?.ticketText?.length && <div style={printStyles.printText}>{formData.ticketText}</div>
          }
          <div style={{borderTop: "2px solid #000", paddingBottom: "5px", paddingTop: "15px", fontSize: "10px"}}>
            {`Order Taken: ${new Date().toLocaleString('en-US', DIRECT_PRINT_DATE_OPTIONS)}`}
          </div>
        </div>
      </div>
    );
  }

  return (
    <> { isLoading ? 
      (
        <EditSkeleton />
      )
      : 
      (
        <div className="paperContent customScrollbar"> 
          <Card sx={{paddingBottom: ".5rem", marginBottom: "1rem"}}>
            <CardContent>
              <Typography variant="h5">
                Additional Information
              </Typography>
              <BaseCheckBox
                control={control}
                label={"Missing Item"}
                name={"missingItem"}
                errors={errors}
                sx={{padding: ".375rem .5rem", marginLeft: "1rem"}}
              />
              <BaseCheckBox
                control={control}
                label={"Manager Required"}
                name={"managerRequired"}
                errors={errors}
                sx={{padding: ".375rem .5rem", marginLeft: "1rem"}}
              />
              <BaseCheckBox
                control={control}
                label={"Additional Order"}
                name={"additionalOrder"}
                errors={errors}
                sx={{padding: ".375rem .5rem", marginLeft: "1rem"}}
              />
            </CardContent>
          </Card>
          <Card sx={{marginBottom: "1rem", padding: "0 1%"}}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Resident / Dining Area
              </Typography>
              <Button
                control={control}
                name={"residentId"}
                sx={{
                  backgroundColor: "#e0e0e0", 
                  color: "#000", 
                  fontWeight: "bold", 
                  marginBottom: ".5rem",
                  "&:hover": {
                    backgroundColor: "#c5c5c5 !important"
                  }
                }} 
                fullWidth 
                variant="contained" 
                onClick={() => setResidentOpen(true)}>
                Resident: {selectedResidentText || "- CLICK TO SELECT -"}
              </Button>
              <Button 
                control={control}
                name={"diningAreaId"}
                sx={{
                  backgroundColor: "#e0e0e0", 
                  color: "#000", 
                  fontWeight: "bold",
                  marginBottom: ".5rem",
                  "&:hover": {
                    backgroundColor: "#c5c5c5 !important"
                  }
                }} 
                fullWidth 
                variant="contained" 
                onClick={() => setDiningOpen(true)}>
                Dining Area: {selectedDiningAreaText || "- CLICK TO SELECT -"}
              </Button>
              <SelectDiningArea 
                open={diningOpen}
                itemSelected={handleDiningChange}
                title="Select Dining Area"
                diningAreas={diningAreas}
              />
              <SelectResident 
                open={residentOpen}
                itemSelected={handleResidentChange}
                title="Select Resident"
                residents={residents}
              />
            </CardContent>
          </Card>
          <Card sx={{padding: "0 1%", marginBottom: "1rem"}}>
            <CardContent>
              <Typography variant="h5">
                Note
              </Typography>
              <BaseInput 
                control={control}
                errors={errors}
                id="ticketText"
                name="ticketText"
                label="Cook's Ticket Text"
                multiline
                variant="outlined"
                rows="4"
                fullWidth
                margin="normal"
              />
            </CardContent>
          </Card>
          <div style={{padding: "0 1%"}}> 
            <Button
              variant="contained"
              fullWidth
              type="submit"
              color="primary"
              onClick={handleSubmit(handleSubmitClick)}
              sx={{fontWeight: "bold"}}
            >
              Submit Cook&apos;s Ticket
            </Button>
          </div>
          <InfoModal 
            open={modalOpen}
            modalText={modalText}
            textVariant="h6"
            handleClose={handleModalClose}
          />
          <ConfirmModal
            open={confirmOpen}
            modalText={confirmText}
            textVariant="h5"
            handleClose={handleConfirmClose}
            confirmText="SUBMIT"
            textWeight="bold"
            cancelText="CANCEL"
          />
          {renderPrintContent()}
        </div>
      )
    }
    </>
  )
}