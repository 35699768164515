import React from "react";
import { RouterProvider, useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./styles/theme";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./utils/ApplicationInsightsService";
import { SnackbarProvider } from "notistack";
import ErrorPage from "./components/shared/errorPage/ErrorPage";
import { useMsalEvents } from "hooks/useMSALEvents";
import { router } from "routes/Routes";
import { LicenseInfo } from "@mui/x-data-grid-pro";

function App({ pca }) {
  useMsalEvents(pca);

  LicenseInfo.setLicenseKey('3b99461ba280278f8d358aa86d80e4a5Tz05NzYwOSxFPTE3NTcxODU0MjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==');

  return (
    <MsalProvider instance={pca}>
      <AppInsightsErrorBoundary appInsights={reactPlugin} onError={ErrorPage}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            autoHideDuration={3000}
            disableWindowBlurListener={true}>
              <RouterProvider router={router}>
                <ClientSideNavigation pca={pca}>
                  <AppInsightsContext.Provider value={reactPlugin}>
                    <div className="app"/>
                  </AppInsightsContext.Provider>
                </ClientSideNavigation>
              </RouterProvider>
            </SnackbarProvider>
        </ThemeProvider>
      </AppInsightsErrorBoundary>
    </MsalProvider>
  );
}

function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return children;
}

export default App;
