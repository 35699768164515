import { AppBar, Dialog, Divider, IconButton, List, ListItemButton, ListItemText, Slide, Toolbar, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectDiningArea({ open, diningAreas, itemSelected, title, enableAll}) {

  const handleClose = () => {
    itemSelected();
  }

  const renderDiningAreas = () => {
    const uiArray = [];

    if (enableAll) {
      uiArray.push(
        <ListItemButton key={"listAll"} onClick={() => itemSelected('all', 'All')}>
          <ListItemText fontWeight="bold" primary={"All"} />
        </ListItemButton>
      );
      uiArray.push(<Divider key={"dividerAll"} />);
    }

    diningAreas?.forEach((area, idx) => {
      uiArray.push(
        <ListItemButton key={`list${area.id}`} onClick={() => itemSelected(area.id, area.name)}>
          <ListItemText fontWeight="bold" primary={area?.name} />
        </ListItemButton>
      );

      if (idx < diningAreas?.length - 1) {
        uiArray.push(<Divider key={`divider${area.id}`} />)
      }
    })

    return uiArray;
  } 

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
        <AppBar position="static">
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" fontWeight="bold" color="inherit" sx={{marginLeft: ".25rem"}}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {renderDiningAreas()}
        </List>

      </Dialog>
  );
}