import React from "react";
import logo from "../../../assets/imgs/DiningRD-Logo-Color_Tag.png";
import {
  useMsal,
} from "@azure/msal-react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { loginRequest } from "authConfig";

export default function Login() {
  const { instance } = useMsal();

  const handleLogin = async () => {
    await instance.loginRedirect({...loginRequest, prompt: "login"});
  }

  return (
    <div className="unauthenticatedHomePage">
      <div className="logoHeader">
        <img className="centerLogo" src={logo} />
        <div className="loginMessage">
          <Button
            component={Link}
            color={"primary"}
            className="baseButton"
            variant="contained"
            onClick={handleLogin}>
            Click here to login
          </Button>
        </div>
      </div>
    </div>
  )
}
