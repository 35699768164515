import { 
  Accordion, 
  AccordionDetails, 
  AccordionSummary, 
  Button, 
  Card, 
  CardContent, 
  Checkbox, 
  Collapse, 
  FormControlLabel, 
  IconButton, 
  MenuItem, 
  Select, 
  Table, 
  TableBody, 
  TableCell, 
  TableRow, 
  TextField, 
  Typography 
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import "./Order.css";
import { useGetMenuQuery, useGetRecipeImageUrlQuery } from "store/apis/MenusApi";
import { EditSkeleton } from "components/admin/admin.overlays";
import { useGetMealAddOnsQuery } from "store/apis/MealsApi";
import { useGetResidentActiveMealsByIdQuery, useGetResidentsAllQuery, useGetResidentTableSelectionQuery } from "store/apis/ResidentsApi";
import { useGetDiningAreasQuery } from "store/apis/DiningAreasApi";
import Media from "react-media";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import HasSubRecipesIcon from "@mui/icons-material/Dehaze";
import diamond from "assets/imgs/diamond.png";
import Favorite from "@mui/icons-material/Favorite";
import { useDispatch, useSelector } from "react-redux";
import { selectGuestInfo, selectMenuInfo, setSelectedMeal } from "store/slices/orderSlice/OrderSlice";
import InfoModal from "components/shared/infoModal/InfoModal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ImageModal from "components/shared/imageModal/ImageModal";
import _ from "lodash";
import SubRecipeModal from "components/shared/subRecipeModal/SubRecipeModal";
import MoneyInput from "@nodejsii/react-money-input";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import OrderConfirmModal from "components/shared/orderConfirmModal/OrderConfirmModal";
import { useAddHeldOrderMutation, useAddResidentOrderMutation, useDeleteFutureOrderMutation, useDeleteHeldOrderMutation } from "store/apis/OrdersApi";
import { DEFAULT_EMPTY, DIRECT_PRINT_DATE_OPTIONS, GUEST_TYPES, PAYMENT_TYPES } from "store/store.constants";

//Cannot use CSS className for print output, must use inline styles
const printStyles = {
  printGeneral: {
    display: 'flex',
    paddingBottom: '0px',
    paddingTop: '0px'
  },
  printDiv: {
    paddingBottom: '0px',
    paddingTop: '0px'
  },
}

export default function Order({ residentId, tableSideSetupId, isGuest, updateHeldOrders, isDirectPrint, allowMealCardNotes, currentUserData, handlePrint }) {
  const [ params ] = useSearchParams(); 
  const currentLocation = useLocation();
  const canHold = params.get('current') === 'true' || residentId === 0;// current or guest
  const isHeldOrder = params.get('from') === 'held';
  const isSubmitted = params.get('from') === 'submitted';
  const fromSP = isHeldOrder || isSubmitted;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDiningAreaId, setSelectedDiningAreaId] = useState("");
  const [selectedTableName, setSelectedTableName] = useState("");
  const [outOfBuilding, setOutOfBuilding] = useState(false);
  const [declinedMeal, setDeclinedMeal] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(null);
  const [orderInfo, setOrderInfo] = useState({
    menuItems: [],
    alternateItems: [],
    order: {}
  });

  const [itemsChecked, setItemsChecked] = useState({
    alternateItems: {},
    menuItems: {},
  });
  const [collapsedRowStatus, setCollapsedRowStatus] = useState([
    {
      rowId: 0,
      colStatus: false,
    },
  ]);

  const [selectedSubRecipes, setSelectedSubRecipes]= useState([]);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [imageRecipeId, setImageRecipeId] = useState(0);
  const [recipeImageUrl, setRecipeImageUrl] = useState("");
  const [openRecipeImage, setOpenRecipeImage] = useState(false);
  
  const [subRecipeModalOpen, setSubRecipeModalOpen] = useState(false);
  const [currentSubRecipe, setCurrentSubRecipe] = useState(null);
  const [showAdditionalCharges, setShowAdditionalCharges] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmType, setConfirmType] = useState(false);

  const [noteText, setNoteText] = useState("");
  const [addlCharges, setAddlCharges] = useState(0.00);

  const guestInfo = useSelector(selectGuestInfo)

  useEffect(() => {
    //if refreshed during guest order, not an edit from hold (fromSP), send user back to guest info page to start over
    if (!guestInfo?.guestName 
      && currentLocation.pathname.includes('/takeorder/guest/order')
      && !fromSP) {
        navigate(`/takeorder/guest`);
    }
  }, [guestInfo]);

  
  const storedMenuInfo = useSelector(selectMenuInfo);

  useEffect(() => {
    if (fromSP && storedMenuInfo) {
      let menuItemsChecked = Object.create({});
      let selectedSubRecipes = [];

      setDeclinedMeal(!!storedMenuInfo?.order?.declinedMeal);
      setOutOfBuilding(!!storedMenuInfo?.order?.outOfBuilding);

      storedMenuInfo.menuItems.forEach((item) => {
        menuItemsChecked[item.itemId] = true;

        if (item.subItemList?.length) {
          selectedSubRecipes.push(...item.subItemList.map((sub) => {
            return {
              ...sub,
              parentRecipeId: item.itemId,
              isAlternateItem: false
            }
          }));
          setCollapsedRowStatus((prev) => [...prev, {colStatus: true, rowId: item.itemId}]);
        }
      });

      let altItemsChecked = Object.create({});

      storedMenuInfo.alternateItems?.forEach((item) => {
        altItemsChecked[item.itemId] = true;

        if (item.subItemList?.length) {
          selectedSubRecipes.push(...item.subItemList.map((sub) => {
            return {
              ...sub,
              parentRecipeId: item.itemId,
              isAlternateItem: true
            }
          }));
          setCollapsedRowStatus((prev) => [...prev, {colStatus: true, rowId: item.itemId}]);
        }
      });

      setItemsChecked((prev) =>( {
        ...prev,
        alternateItems: altItemsChecked,
        menuItems: menuItemsChecked,
      }));

      setSelectedSubRecipes([...selectedSubRecipes]);

      setOrderInfo(() => ({
        guestPrice: storedMenuInfo.guestPrice ?? 0,
        menuItems: [...storedMenuInfo.menuItems.map((item) =>{ return {...item, subItemList: [...item.subItemList]} })],
        alternateItems: [...storedMenuInfo.alternateItems.map((item) =>{ return {...item, subItemList: [...item.subItemList]} })],
        order: {...storedMenuInfo.order}
      }));

      setNoteText(storedMenuInfo?.order?.note || '');

      if (storedMenuInfo?.order?.note?.length && storedMenuInfo?.order?.additionalCharges > 0){
        setShowAdditionalCharges(true);
      }
      setAddlCharges(storedMenuInfo?.order?.additionalCharges || 0.00);
    }
  }, [storedMenuInfo]);

  const {
    data: imgUrl,
    isFetching: fetchingUrl,
    isError: urlError,
    isSuccess: urlSuccess
  } = useGetRecipeImageUrlQuery(imageRecipeId,
    {refetchOnMountOrArgChange: true, skip: !imageRecipeId}
  );
  
  useEffect(() => {
    if (!fetchingUrl && urlSuccess) {
      setRecipeImageUrl(imgUrl);
    }
  }, [fetchingUrl, urlSuccess]);

  useEffect(() => {
    if (urlError) {
      setRecipeImageUrl("");
    }
    
    if(imageRecipeId) {
      setOpenRecipeImage(true);
    }
  }, [recipeImageUrl, urlError]); //still open with falsy value, template will show error
  
  const residentIdForQueries = isGuest ? guestInfo?.selectedResident ? guestInfo?.selectedResident : null : residentId; // I dont like this nested ternary, refactor it

  const {
    data: menuInfo,
    isFetching: fetchingMenu
  } = useGetMenuQuery(
    {
      residentId: residentId,
      setupId: tableSideSetupId,
      isGuest: !!isGuest,
      includeDiningAreas: true,
      guestResidentId: isGuest && guestInfo?.selectedResident ? guestInfo?.selectedResident : 0,
      guestName: isGuest ? guestInfo?.guestName?.trim() : "" ,
      guestBillingTypeId: isGuest ? guestInfo?.paymentDone : 0
    }, 
    { skip: ((!residentId || !tableSideSetupId) && !isGuest) }
  );
  
  useEffect(() => {
    if (menuInfo && !fromSP) {
      const newOrderInfo = Object.assign({}, menuInfo);
      newOrderInfo['menuItems'] = [];
      newOrderInfo['alternateItems'] = [];
      setOrderInfo(newOrderInfo);
    } else if (menuInfo && fromSP) {
      //just add menuChoices we need from API call when using stored data 
      setOrderInfo((prev) => ({
        ...prev,
        menuChoices: [...menuInfo.menuChoices],
        alternateChoices: [...menuInfo.alternateChoices],
        orderId: menuInfo?.orderId,
        roomBed: menuInfo?.roomBed,
        residentName: menuInfo?.residentName,
      }));
    }
  }, [menuInfo]);

  const {
    data: mealAddons,
    isFetching: fetchingAddons
  } = useGetMealAddOnsQuery(  
    { 
      residentId: residentId, 
      setupId: tableSideSetupId, 
      isGuest: !!isGuest
    },
    { skip: ((!residentId || !tableSideSetupId) || isGuest) }
  );

  const {
    data: residentTables,
    isFetching: fetchingTable
  } = useGetResidentTableSelectionQuery(residentIdForQueries,
    { skip: !residentId && !guestInfo.selectedResident }
  )
  
  const {
    data: diningAreas,
    isFetching: fetchingAreas
  } = useGetDiningAreasQuery();

  const {
    data: residents,
    isFetching: fetchingResidents,
    // refetch: refetchResidentList
  } = useGetResidentsAllQuery();

  const {
    data: activeMeals,
    isFetching: fetchingMeals,
  } = useGetResidentActiveMealsByIdQuery(residentId,
    { refetchOnMountOrArgChange: true, skip: !residentId }
  );
  
  const isLoading = fetchingMenu 
                  || fetchingAddons 
                  || fetchingTable 
                  || fetchingAreas 
                  || fetchingResidents
                  || fetchingMeals;
  
  useEffect(() => {
    if (menuInfo && diningAreas) {
      dispatch(setSelectedMeal(menuInfo?.mealName)); // for display in navbar

      const mealName = menuInfo?.mealName?.toLowerCase()?.trim();
      let diningAreaId = "";
      let diningTableName = "";
      
      // find current meal and table/dining area to setState.
      if (!fromSP) {
        if (mealName?.startsWith('breakfast')) {
          diningAreaId = residentTables?.[0]?.diningAreaId > 0 ? residentTables?.[0]?.diningAreaId : "";
          diningTableName = residentTables?.[0]?.tableName || "";
        } else if (mealName?.startsWith('lunch')) {
          diningAreaId = residentTables?.[1]?.diningAreaId > 0 ? residentTables?.[1]?.diningAreaId : "";
          diningTableName = residentTables?.[1]?.tableName || "";
        } else if (mealName?.startsWith('dinner')) {
          diningAreaId = residentTables?.[2]?.diningAreaId > 0 ? residentTables?.[2]?.diningAreaId : "";
          diningTableName = residentTables?.[2]?.tableName || "";
        }
      } else {
        diningAreaId = storedMenuInfo?.order?.diningAreaId;
        diningTableName = storedMenuInfo?.order?.tableName;
      }
      
      selectDiningArea(diningAreaId);
      selectTable(diningTableName);
      
      if (menuInfo.fluidRestriction) {
        setModalTitle('Fluid Restriction')
        setModalText(`This resident has a fluid restriction!`);
        setInfoModalOpen(true);
      }
    }
  }, [residentTables, menuInfo, diningAreas]);

  useEffect(() => {
    let activeMeal;
    
    activeMeals?.days?.forEach((day) => {
      day?.meals?.forEach((meal) => {
        if (meal?.setupId == tableSideSetupId) {
          activeMeal = meal;
        }
      })
    });

    if (activeMeal?.orderTaken && !fromSP) {
      setModalTitle("ALERT");
      setModalText("You have already taken an order for this person.\nThis order will be sent in addition to the original order.\nIf you want to reprint the original order, go to the 'Submitted' orders tab.")
      setInfoModalOpen(true);
    }
  }, [activeMeals]);
  
  const handleModalClose = () => {
    setInfoModalOpen(false);
  }

  const checkForDietNameSeparator = () => {
    return (menuInfo.dietName !== "N/A" && menuInfo.dietName && menuInfo.dietTextureName !== "N/A" && menuInfo.dietTextureName);
  }

  const checkForDietOtherSeparator = () => {
    return (menuInfo.dietName !== "N/A" && menuInfo.dietName && menuInfo.dietTextureName !== "N/A" &&
      menuInfo.dietTextureName && menuInfo.dietOther !== "N/A" && menuInfo.dietOther) ||
      (menuInfo.dietName && !menuInfo.dietTextureName && menuInfo.dietOther) ||
      (!menuInfo.dietName && menuInfo.dietTextureName && menuInfo.dietOther)
  }

  const selectDiningArea = (diningAreaId) => {
    const newTables = diningAreas.find((area) => area.id === diningAreaId)?.tables;
    setSelectedDiningAreaId(() => diningAreaId);
    renderTables(); //update Select component render list to prevent warning in next if-condition

    if (!newTables?.some((table) => table.name === selectedTableName)) {
      setSelectedTableName(newTables?.[0]?.name ?? 'N/A');
    }

  }

  const renderDiningAreas = () => {
    const areas = [];

    if (diningAreas?.length) {
      diningAreas.forEach((area) => {
        areas.push(
          <MenuItem key={area.id} value={area.id}>
            <span className="general-text">{area.name}</span>
          </MenuItem>
        );
      });
    }

    return areas;
  }

  const selectTable = (tableName) => {
    setSelectedTableName(tableName);
    setOrderInfo((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        tableName: tableName
      }
    }));
  }

  const renderTables = () => {
    const tables = [];

    if (selectedDiningAreaId) {
      const diningArea = diningAreas.find((area) => area.id === selectedDiningAreaId);

      if (diningArea && diningArea?.tables?.length) {
        diningArea.tables.forEach((table) => {
          tables.push(
            <MenuItem key={table.id} value={table.name}>
              <span className="general-text">{table.name}</span>
            </MenuItem>
          );
        });
      } else {
        tables.push(
          <MenuItem key={"N/A"} value={"N/A"}>
          <span className="general-text">{"N/A"}</span>
          </MenuItem>
        );
      }
    }

    return tables;
  }

  const handleOutOfBuildingChange = () => {
    setOutOfBuilding((prev) => !prev);
    setDeclinedMeal(false);
  }

  const handleDeclinedMealChange = () => {
    setDeclinedMeal((prev) => !prev);
    setOutOfBuilding(false);
  }

  useEffect(() => {
    if (outOfBuilding || declinedMeal) {
      // only removes main menu items, handle alt items, subRecipes, and items checked previously manually
      setIsSelectAll(false); 
      setOrderInfo((prev) => ({
        ...prev,
        alternateItems: []
      }));
      setSelectedSubRecipes([]);
      setItemsChecked({
        alternateItems: {},
        menuItems: {}
      });
    }
  }, [outOfBuilding, declinedMeal]);

  useEffect(() => {
    if (isSelectAll !== null) {
      menuInfo?.menuChoices?.forEach((item) => {
        if (!item?.isAllergy) {
          handleMenuItemClick(item, 'menuItems', isSelectAll);
        }
      });
  
      // concurrency memory reference issue needs workaround for deselect all
      // it removes from the checked item lists properly, so visually it looks fine
      // but ends up leaving the actual items in the array. This bug exists in legacy 
      // and a reset of menuItems to an empty array in the state object is the simplest solution
      if (!isSelectAll) {
        setOrderInfo((prev) => ({
          ...prev,
          menuItems: []
        }))
      }
    }
  }, [isSelectAll]);

  const removeSelectedSubRecipeFromList = (parentRecipeId, isAltRecipe) => {
    setSelectedSubRecipes((prev) => [...prev.filter((sub) => sub.parentRecipeId !== parentRecipeId || sub.isAlternateItem !== isAltRecipe)]);
    setCollapsedRowStatus((prev) => [...prev.filter((row) => row.rowid !== parentRecipeId || row.isAlternateItem !== isAltRecipe)]);
  }

  const handleMenuItemClick = (item, choice, isChecked) => {
    const tempOrderInfo = Object.assign({}, orderInfo);
    const tempItemsChecked = itemsChecked;
    let guestPrice = orderInfo?.['guestPrice'] ?? 0;

    if (isChecked) {

      tempOrderInfo[choice].push({
        itemId: item.recipeId,
        quantity: 1,
        guestPrice: item.guestPrice,
        portionSize: item.portionSize,
        name: item.recipeName,
        menuCategory: item.menuCategory,
        isSubstitution: item.isSubstitution
      });

      tempItemsChecked[choice][item.recipeId] = true;
      guestPrice += item.guestPrice;

      if (menuInfo.fluidRestriction && item.applicableToFluidRestriction) {
        setModalTitle("Fluid Restriction")
        setModalText("This resident has a fluid restriction.\n" + 
          "Make sure that this selection doesn't exceed the resident's restriction."
        );
        setInfoModalOpen(true);
      }
    } else {
      tempOrderInfo[choice] = tempOrderInfo[choice]?.filter((orderItem) => {
        if (orderItem.itemId === item.recipeId) {
          guestPrice = guestPrice - (orderItem.guestPrice * orderItem.quantity);
          orderItem.quantity = '';
        }
        return orderItem.itemId !== item.recipeId;
      });

      tempItemsChecked[choice][item.recipeId] = false;
      removeSelectedSubRecipeFromList(item.recipeId, item.isAlternateRecipe);
    }

    setItemsChecked(tempItemsChecked);
    setOrderInfo((prev) => ({
      ...prev,
      alternateItems: [...tempOrderInfo.alternateItems],
      menuItems: [...tempOrderInfo.menuItems],
      guestPrice: guestPrice
    }));
  }

  const selectQuantity = (item, choice, value) => {
    const tempOrderInfo = Object.assign({}, orderInfo);

    tempOrderInfo[choice]?.forEach((orderItem) => {
      if (orderItem.itemId === item.recipeId) {
        setOrderInfo((prev) => ({
          ...prev,
          guestPrice: prev.guestPrice + ((value - orderItem.quantity) * item.guestPrice)
        }));

        orderItem.quantity = value;
      }
    });
  }

  const renderQuantity = (maxQuantity) => {
    const sizes = [];
    const servingSizes = [1/2];

    for (let i = 1; i <= maxQuantity; i++) {
      servingSizes.push(i);
    }

    servingSizes.forEach((size) => {
      sizes.push(
        <MenuItem key={size} value={size}>
          <span className="general-text">{size}</span>
        </MenuItem>
      );
    });

    return sizes;
  }

  const renderSubMenuModal = (event, subRecipe) => {
    event.stopPropagation();
    setCurrentSubRecipe(subRecipe);
    setSubRecipeModalOpen(true);
  }

  const handleSubRecipeClose = (recipeId, selectedSubRecipes) => {
    const isAlternate = currentSubRecipe?.isAlternateRecipe;

    if (!isAlternate) {
      const rowId = currentSubRecipe.recipeId;
      let tempCollapseStatus = collapsedRowStatus;
      const totalSubsForItem = selectedSubRecipes?.length || 0;

      if (totalSubsForItem > 0) {
        const temp = tempCollapseStatus.filter((row) => row.rowId === rowId);

        if (temp?.length > 0) {
          temp.at(0).colStatus = true;
        } else {
          tempCollapseStatus.push({ rowId: rowId, colStatus: true });
        }
      } else {
        tempCollapseStatus = tempCollapseStatus.filter((row) => row.rowId !== rowId);
      }

      setCollapsedRowStatus(tempCollapseStatus);
    }

    setSelectedSubRecipes((prev) => [...prev.filter((old) => old.parentRecipeId !== recipeId), ...selectedSubRecipes]);
    setSubRecipeModalOpen(false);
  }

  const handlePopUpImage = (recipeId) => {
    // if we already have the imageUrl for that recipe, just open the modal
    if (imageRecipeId === recipeId) {
      setOpenRecipeImage(true);
    } else {
      setImageRecipeId(recipeId);
    }
  }

  const renderMenuItems = () => {
    const menuItems = [];

    if (menuInfo) {
      menuInfo?.menuChoices?.forEach((item) => {
        let selectedValue = "";
        orderInfo?.menuItems?.forEach((orderItem) => {
          if (orderItem?.itemId === item.recipeId) {
            selectedValue = orderItem?.quantity;
          }
        });

        if (selectedValue === undefined) selectedValue = "";

        let flag = itemsChecked?.menuItems?.[item.recipeId];

        let rowStatus = collapsedRowStatus;
        let status = rowStatus?.filter((row) => row?.rowId === item.recipeId);
        let tempStatus = status.length ? status[0]?.colStatus : false;

        menuItems.push(
          <TableRow 
            className='toggleRow'
            style={{
              backgroundColor: flag ? '#46b9bc' : "#FFF",
            }}
            key={item.recipeId}
          >
            <TableCell style={{width: "10px", justifyContent: "center", padding: "0"}}>
              <Grid container justifyContent="center" alignItems="center">
                <IconButton
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (status?.length) {
                      status[0].colStatus = !tempStatus;
                      setCollapsedRowStatus([...rowStatus]);
                    }
                  }}    
                >
                  {tempStatus ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Grid>
            </TableCell>
            <TableCell style={{padding: "0", cursor: "pointer"}} 
            onClick={() => {
              if (!item.isAllergy) {
                handleMenuItemClick(item, "menuItems", !flag)
              }
            }}
            >
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                <Media query="(max-width: 599px)">
                  {(matches) => 
                    matches ? (
                      <span style={{fontSize: "2rem", fontWeight: "fontWeightMedium"}}>{item.recipeName}</span>
                    ) : (
                      <span style={{fontSize: "2rem", fontWeight: "fontWeightMedium"}}>{item.recipeName}</span>
                    )
                  }
                </Media>
              </Grid>
            </TableCell>
            <TableCell>
              {item.isAllergy && (
                  <Grid container justifyContent="center" alignItems="center">
                    <WarningIcon className="warning" />
                      <Typography className="warning">
                        <span className="warning">{" ALLERGY"}</span>
                      </Typography>
                  </Grid>
              )}
            </TableCell>
            <TableCell style={{padding: "0"}}>
              <Grid container justifyContent="flex-end" alignItems="center" flexWrap="nowrap">
                {flag && item?.hasSubRecipes && (
                  <HasSubRecipesIcon style={{color: "#2F4F4F", marginRight: "5px"}} onClick={(event) => renderSubMenuModal(event, item)} />
                )}

                {item.imageAvailable && <CameraAltIcon style={{color: "#2F4F4F", marginRight: "5px"}} onClick={() => handlePopUpImage(item.recipeId)} /> }
                {item.imageAvailable && <ImageModal open={openRecipeImage} sourceUrl={recipeImageUrl} handleClose={() => setOpenRecipeImage(false)} />}
                {item.isHeartHealthy && <Favorite style={{color: "#ab403d", marginRight: "5px"}} />}
                {item.isDiabeticChoice && (
                  <img alt="diamond-icon" src={diamond} style={{width: "27px", height: "28px", marginRight: "5px"}} />
                )} 

                <Select
                  variant="standard"
                  style={{marginRIght: "5px"}}
                  disabled={!flag}
                  value={selectedValue}
                  onChange={(event) => selectQuantity(item, "menuItems", event.target.value)}
                  onClick={(e) => e.stopPropagation()}
                >
                  {renderQuantity(item.maxNumberOfServings)}
                </Select>
              </Grid>
            </TableCell>
          </TableRow>
        );
        const subRecipes = selectedSubRecipes?.filter((sub) => sub.parentRecipeId === item.recipeId && !sub.isAlternateItem);

        subRecipes.forEach((subRecipe) => {
          menuItems.push(
            // Weird nesting but needed to work around valid DOM nesting errors with Collapse (div) element being inserted into table structure
            <TableRow key={subRecipe.recipeId} sx={{backgroundColor: "#46b9bc", height: "auto"}}>
              <TableCell colSpan={4} sx={{padding: "0"}}>
                <Collapse in={tempStatus} timeout="auto" unmountOnExit>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Grid>
                            <span style={{marginLeft: "6rem", fontSize: "2rem"}}>{subRecipe.recipeName}</span>
                          </Grid>
                        </TableCell>
                        <TableCell sx={{paddingRight: ".25rem"}}>
                          <Grid container justifyContent="flex-end" alignItems="center">
                            <span style={{fontSize: "2rem"}}>{subRecipe.quantity}</span>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          )
        });
      });
    }

    return menuItems;
  }

  const renderAlternateItems = () => {
    const alternateComponents = [];

    if (menuInfo !== null) {
      const groupedItems = _.groupBy(menuInfo?.alternateChoices, _.property("menuCategory"));
      for (const key in groupedItems) {
        if (groupedItems[key]) {
          alternateComponents.push(
            <Accordion key={key}  id="alt-accordion" sx={{borderRadius: "0px !important", "&.Mui-expanded": {
              margin: "0px !important"
            }}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  <span className="general-text">{key}</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableBody>{renderListItems(groupedItems[key])}</TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          );
        }
      }
    }

    return alternateComponents;
  }

  const renderListItems = (items) => {
    const list = [];

    _.uniqBy(items, _.property("recipeId"))?.forEach((item) => {
      let flag = itemsChecked?.alternateItems?.[item.recipeId];
      let selectedValue = "";

      orderInfo?.alternateItems?.forEach((alt) => {
        if (alt.itemId === item.recipeId) {
          selectedValue = alt.quantity ?? undefined;
        }
      });

      if (selectedValue === undefined) { selectedValue = ""; }

      list.push(
        <TableRow 
          className="toggleRow" 
          key={item.recipeId}
          sx={{backgroundColor: flag ? "#46b9bc" : "white"}}
        >
          <TableCell 
            sx={{padding: "0", cursor: "pointer"}}           
            onClick={() => {
              if (!item.isAllergy) {
                handleMenuItemClick(item, "alternateItems", !flag)
              }
          }}>
            <Grid container justifyContent="flex-start" alignItems="flex-start">
              <Media query="(max-width: 599px)">
                {(matches) => 
                  matches ? (
                    <span style={{fontSize: "2rem", fontWeight: "fontWeightMedium"}}>{item.recipeName}</span>
                  ) : (
                    <span style={{fontSize: "2rem", fontWeight: "fontWeightMedium"}}>{item.recipeName}</span>
                  )
                }
              </Media>
            </Grid>
          </TableCell>
          <TableCell sx={{padding: "0"}}>
            {item.isAllergy && (
              <Grid container justifyContent="center" alignItems="center">
                <WarningIcon className="warning"/>
                <Typography className="warning">
                  <span className="warning">{" ALLERGY"}</span>
                </Typography>
              </Grid>
            )}
          </TableCell>
          <TableCell sx={{padding: "0"}}>
              <Grid container justifyContent="flex-end" alignItems="center">
                {flag && item?.hasSubRecipes && (
                  <HasSubRecipesIcon style={{color: "#2F4F4F", marginRight: "5px"}} onClick={(event) => renderSubMenuModal(event, item)} />
                )}

                {item.imageAvailable && <CameraAltIcon style={{color: "#2F4F4F", marginRight: "5px"}} onClick={() => handlePopUpImage(item.recipeId)} /> }
                {item.imageAvailable && <ImageModal open={openRecipeImage} sourceUrl={recipeImageUrl} handleClose={() => setOpenRecipeImage(false)} />}
                {item.isHeartHealthy && <Favorite style={{color: "#ab403d", marginRight: "5px"}} />}
                {item.isDiabeticChoice && (
                  <img alt="diamond-icon" src={diamond} style={{width: "27px", height: "28px", marginRight: "5px"}} />
                )} 

                <Select
                  variant="standard"
                  style={{marginRIght: "5px"}}
                  disabled={!flag}
                  value={selectedValue}
                  onChange={(event) => selectQuantity(item, "alternateItems", event.target.value)}
                  onClick={(e) => e.stopPropagation()}
                >
                  {renderQuantity(item.maxNumberOfServings)}
                </Select>
              </Grid>
          </TableCell>
        </TableRow>
      );

      const subRecipes = selectedSubRecipes.filter((sub) => sub.parentRecipeId === item.recipeId && sub.isAlternateItem);

      _.uniqBy(subRecipes, _.property('recipeId')).forEach((subRecipe) => {
        list.push(
          <TableRow key={subRecipe.recipeId} sx={{ backgroundColor: "#46b9bc"}}>
            <TableCell colSpan={2}>
              <span style={{paddingLeft: "5rem", fontSize: "2rem"}}>{subRecipe.recipeName}</span>
            </TableCell>
            <TableCell sx={{marginLeft: "auto", textAlign: "right", paddingRight: ".25rem"}}>
              <span style={{fontSize: "2rem"}}>{subRecipe.quantity}</span>
            </TableCell>
          </TableRow>
        );
      })
    });

    
    return list;
  }

  const handleSubmit = (confirmType) => {
    // enforce selection when not declining or out of building
    if (!selectedDiningAreaId && !outOfBuilding && !declinedMeal) {
      setModalTitle(""); // reset just in case other with title has been used 
      setModalText("Dining Area cannot be blank.")
      setInfoModalOpen(true);
      return;
    }

    if (addlCharges > 100) {
      return;
    }

    const tempMenuItems = [...orderInfo.menuItems];
    const tempAltItems = [...orderInfo.alternateItems];

    tempMenuItems.forEach((item) => {
      const subRecipes = selectedSubRecipes.filter((sub) => sub.parentRecipeId === item.itemId && !sub.isAlternateItem);
      item['subItemList'] = [...subRecipes];
    });

    tempAltItems.forEach((item) => {
      const subRecipes = selectedSubRecipes.filter((sub) => sub.parentRecipeId === item.itemId && sub.isAlternateItem);
      item['subItemList'] = [...subRecipes];
    })

    //update fields we removed from onChange for performance reasons (large object re-writes)
    //and put menu/alt items back with selected subrecipes bound to parent
    setOrderInfo((prev) => ({
      ...prev,
      menuItems: [...tempMenuItems],
      alternateItems: [...tempAltItems],
      order: {
        ...prev.order,
        additionalCharges: addlCharges,
        declinedMeal: declinedMeal,
        diningAreaId: selectedDiningAreaId || 0, //fallback for declined/OOB (call fails as null), otherwise selection is enforced anyway
        guest: isGuest ? guestInfo?.guestName?.trim() : null,
        guestBillingTypeId: isGuest ? guestInfo?.paymentDone : 0,
        guestTypeId: isGuest ? guestInfo?.selectedGuestType : 0,
        note: noteText,
        outOfBuilding: outOfBuilding,
        tableName: selectedTableName || "",
      }
    }));
  
    setConfirmType(confirmType)
    setConfirmModalOpen(true);
  }

  const [
    addHoldOrder,
    {
      isSuccess: holdSuccess,
      isError: holdError
    }
  ] = useAddHeldOrderMutation();

  useEffect(() => {
    if (holdSuccess && !holdError) {
      updateHeldOrders();
      if (isGuest) {
        navigate(`/takeorder/guest`);
      } else {
        navigate('/takeorder/residents');
      }
    }
  }, [holdSuccess, holdError])

  const [
    addNewOrder,
    {
      isSuccess: orderSuccess,
      isError: orderError
    }
  ] = useAddResidentOrderMutation();

  useEffect(() => {
    if (orderSuccess && !orderError) {
      if (fromSP) {
        // attempt to clean up on submit when stored data present
        if (isHeldOrder) {
          deleteHeldOrder(storedMenuInfo?.order?.id); 
        } else if (isSubmitted) {
          deleteOrder(storedMenuInfo?.order?.id);
        }
      } else {
        if (isGuest) {
          navigate(`/takeorder/guest`);
        } else {
          navigate(`/takeorder/residents`);
        }
      }
    }
  }, [orderSuccess, orderError]);

  const [deleteHeldOrder, { isSuccess: deleteHeldSuccess }] = useDeleteHeldOrderMutation();
  const [deleteOrder, { isSuccess: deleteOrderSuccess }] = useDeleteFutureOrderMutation();

  useEffect(() => {
    if (deleteHeldSuccess || deleteOrderSuccess) {
      navigate(`/takeorder/residents`);
    }
  }, [deleteHeldSuccess, deleteOrderSuccess])

  const handleConfirmClose = (canContinue) => {
    setConfirmModalOpen(false);

    if (canContinue) {
      if (confirmType === 'HOLD') {
        addHoldOrder(orderInfo); 
      } else if (confirmType === 'SUBMIT') {
        if (isDirectPrint) {
          const html = document.getElementById('printSection').cloneNode(true);
          handlePrint(html);
        }

        addNewOrder(orderInfo);
      }   
    }
  }

  const getResidentNameById = (residentId) => {
    return residents?.find((resident) => resident.id === residentId)?.name?.split("(")?.[0]?.trim() || "";
  }

  const getDiningAreaNameById = (diningAreaId) => {
    return diningAreas?.find((area) => area.id === diningAreaId)?.name?.trim() || "";
  }

  const renderPrintItems = () => {
    const menuList = [];

    orderInfo.menuItems?.forEach((item) => {
      const quantity = item.quantity == 0.5 ? '1/2' : item.quantity;
      const subRecipes = selectedSubRecipes.filter((sub) => sub.parentRecipeId === item.itemId);

      menuList.push(
        <div key={item.itemId} style={printStyles.printDiv}>
          <span>{`${quantity} - ${item.portionSize} ${item.name}`}</span>
        </div>
      );

      subRecipes.forEach((sub) => {
        menuList.push(
          <div key={sub.recipeId} style={{paddingY: "0px", paddingLeft: "24px"}}>
          <span>{`${quantity} - ${sub.recipeName}`}</span>
        </div>
        )
      });
    });

    orderInfo.alternateItems?.forEach((alt) => {
      const quantity = alt.quantity == 0.5 ? '1/2' : alt.quantity;
      const subRecipes = selectedSubRecipes.filter((sub) => sub.parentRecipeId === alt.itemId);

      menuList.push(
        <div key={alt.itemId} style={printStyles.printDiv}>
          <span>{`${quantity} - ${alt.portionSize} ${alt.name}`}</span>
        </div>
      );

      subRecipes.forEach((sub) => {
        menuList.push(
          <div key={sub.recipeId} style={{paddingY: "0px", paddingLeft: "24px"}}>
          <span>{`${quantity} - ${sub.recipeName}`}</span>
        </div>
        )
      });
    });

    return menuList;
  }

  const renderPrintContent = () => {
    const diningAreaName = getDiningAreaNameById(orderInfo?.order?.diningAreaId);

    return (
      <div style={{padding: "10px 5px 5px", fontSize: "15px"}}>
        <div style={{fontSize: "13px", fontWeight: "bold", paddingY: "5px"}}>
          {isGuest ? orderInfo?.order?.guest : orderInfo?.residentName}
        </div>
        {!!orderInfo.order.guestOfResidentId && (
          <div style={printStyles.printDiv}>Guest/Employee: {getResidentNameById(orderInfo.order.guestOfResidentId)}</div>
        )}
        {isGuest && (
          <div style={printStyles.printDiv}>
            Pamyent Status: {PAYMENT_TYPES[orderInfo.order.guestBillingTypeId]}  
          </div>
        )}
        <div style={{borderBottom: "2px solid #000", paddingBottom: "5px", marginBottom: "5px"}}>
          {diningAreaName !== "" && (
            <div style={printStyles.printGeneral}>
              Dining Room: {diningAreaName}  
            </div>
          )}
          {!DEFAULT_EMPTY.includes(orderInfo?.order?.tableName) && (
            <div style={printStyles.printGeneral}>
              Table: {orderInfo.order.tableName}  
            </div>
          )}
          {(!DEFAULT_EMPTY.includes(orderInfo.dietName) 
            || !DEFAULT_EMPTY.includes(orderInfo.dietTextureName) 
            || !DEFAULT_EMPTY.includes(orderInfo.dietOther)) && (
              <div style={printStyles.printGeneral}>
                Diet: {orderInfo.dietName}
                <span>
                  {checkForDietNameSeparator(orderInfo) && `/`}
                  {!DEFAULT_EMPTY.includes(orderInfo.dietTextureName) && orderInfo.dietTextureName !== "Regular" && `${orderInfo.dietTextureName}`}
                  {checkForDietOtherSeparator(orderInfo && `/`)}
                  {!DEFAULT_EMPTY.includes(orderInfo.dietOther) && `${orderInfo.dietOther}`}
                </span>
              </div>
          )}
          {!isGuest && !DEFAULT_EMPTY.includes(orderInfo.roomBed) && (
            <div style={printStyles.printGeneral}>
              Room Number: {orderInfo.roomBed}
            </div>
          )}
          {allowMealCardNotes && !!orderInfo.notes?.length && (
            <div style={printStyles.printGeneral}>
              Notes: {orderInfo.notes.join(", ")}
            </div>
          )}
          {!DEFAULT_EMPTY.includes(orderInfo.fluidRestriction) && (
            <div style={printStyles.printGeneral}>
              Restriction: {orderInfo.fluidRestriction}
            </div>
          )}
          {!DEFAULT_EMPTY.includes(orderInfo.adaptiveEquipment) && (
            <div style={printStyles.printGeneral}>
              Adaptive Equip: {orderInfo.adaptiveEquipment}
            </div>
          )}
        </div>
        {!DEFAULT_EMPTY.includes(orderInfo.allergies) && (
          <div style={printStyles.printGeneral}>
            Allergies: {orderInfo.allergies}
          </div>
        )}
        {renderPrintItems()}
        {!DEFAULT_EMPTY.includes(noteText) && (
          <div style={printStyles.printGeneral}>
            Note: {noteText}
          </div>
        )}
        <div style={{borderTop: "2px solid #000", marginTop: "5px", paddingY: "5px", fontSize: "10px"}}>
          {`Order Taken: ${new Date().toLocaleString("en-US", DIRECT_PRINT_DATE_OPTIONS)}, by ${currentUserData?.emailAddress}`}
        </div>
      </div>
    );
  }

  return (
    <> {
      isLoading ? (
        <EditSkeleton />
      )
      : 
      (
        <div className="paperContent customScrollbar">
          <Card sx={{marginBottom: "1rem"}}>
            <CardContent>
              <Typography variant="h5">
                {menuInfo && menuInfo.residentName}
              </Typography>
              <Table>
                <TableBody>

                  {menuInfo &&
                    ((menuInfo.dietName !== 'N/A' && !!menuInfo.dietName) ||
                      (menuInfo.dietTextureName !== 'N/A' && !!menuInfo.dietTextureName) || 
                      (menuInfo.dietOther !== 'N/A' && !!menuInfo.dietOther)) && (
                        <TableRow>
                          <TableCell>
                            <span className="general-text">Diet</span>
                          </TableCell>
                          <TableCell>
                            <span className="general-text">{menuInfo && menuInfo.dietName !== "N/A" && menuInfo.dietName}</span>
                            <span className="general-text">{checkForDietNameSeparator() && "/"}</span>
                            <span className="general-text">
                              {menuInfo.dietTextureName !== "" && menuInfo.dietTextureName !== "Regular" && `${menuInfo.dietTextureName}`}
                            </span>
                            <span className="general-text">{checkForDietOtherSeparator() && "/"}</span>
                            <span className="general-text">{menuInfo.dietOther !== "" && `${menuInfo.dietOther}`}</span>{" "}
                          </TableCell>
                        </TableRow>
                  )}
                  {menuInfo && menuInfo.liquidConsistency !== "N/A" && menuInfo.liquidConsistency !== "" && menuInfo.liquidConsistency !== null && (
                    <TableRow>
                      <TableCell>
                        <span className="general-text">Liquid Consistency</span>
                      </TableCell>
                      <TableCell>
                        <span className="general-text">{menuInfo && menuInfo.liquidConsistency !== "N/A" && menuInfo.liquidConsistency}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {menuInfo && menuInfo.fluidRestriction !== "N/A" && menuInfo.fluidRestriction !== "" && (
                    <TableRow>
                      <TableCell>
                        <span className="general-text">Fluid Restriction</span>
                      </TableCell>
                      <TableCell>
                        <span className="general-text">{menuInfo && menuInfo.fluidRestriction !== "N/A" && menuInfo.fluidRestriction}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {menuInfo && menuInfo.allergies !== "N/A" && menuInfo.allergies !== "" && (
                    <TableRow>
                      <TableCell>
                        <span className="general-text">Allergies</span>
                      </TableCell>
                      <TableCell>
                        <span className="general-text">{menuInfo && menuInfo.allergies !== "N/A" && menuInfo.allergies}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {menuInfo && menuInfo.dislikes !== "N/A" && menuInfo.dislikes !== "" && (
                    <TableRow>
                      <TableCell>
                        <span className="general-text">Dislikes</span>
                      </TableCell>
                      <TableCell>
                        <span className="general-text">{menuInfo && menuInfo.dislikes !== "N/A" && menuInfo.dislikes}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {menuInfo && menuInfo.adaptiveEquipment !== "N/A" && menuInfo.adaptiveEquipment !== "" && (
                    <TableRow>
                      <TableCell>
                        <span className="general-text">Adaptive Equipment</span>
                      </TableCell>
                      <TableCell>
                        <span className="general-text">{menuInfo && menuInfo.adaptiveEquipment !== "N/A" && menuInfo.adaptiveEquipment}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <span className="general-text">Meal Price</span>
                    </TableCell>
                    <TableCell>
                      <span className="general-text">
                        {orderInfo && orderInfo.guestPrice !== "N/A" && "$" + Number(orderInfo.guestPrice).toFixed(2)}
                      </span>
                    </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className="general-text">Dining Area</span>
                  </TableCell>
                  <TableCell>
                    <Select 
                      variant="standard" 
                      value={selectedDiningAreaId} 
                      onChange={(event) => selectDiningArea(event.target.value)}
                    >
                      {renderDiningAreas()}
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className="general-text">Table</span>
                  </TableCell>
                  <TableCell>
                      <Select variant="standard" value={selectedTableName} onChange={(event) => selectTable(event.target.value)}>
                        {renderTables()}
                      </Select>
                  </TableCell>
                </TableRow>
                {!!(menuInfo?.order?.residentId === 0 || menuInfo?.order?.guestTypeId > 0) && (
                  <TableRow>
                    <TableCell>
                      <span className="general-text">Guest Type</span>
                    </TableCell>
                    <TableCell>
                        <span className="general-text">
                          {!DEFAULT_EMPTY.includes(guestInfo?.selectedGuestType) && GUEST_TYPES[guestInfo?.selectedGuestType || 0]}
                        </span>
                    </TableCell>
                  </TableRow> 
                )}
                {!!(mealAddons && mealAddons.length) && (
                  <TableRow>
                    <TableCell>
                      <span className="general-text">Meals AddOn</span>
                    </TableCell>
                    <TableCell>
                      {mealAddons.map((item, idx) => {
                        return <div className="general-text" key={idx}>{item?.trim()}</div>;
                      })}
                    </TableCell>
                  </TableRow>
                )}
                {menuInfo && menuInfo.notes && menuInfo.notes.length > 0 && (
                  <TableRow>
                    <TableCell>
                      <span className="general-text">Notes</span>
                    </TableCell>
                    <TableCell>
                      {menuInfo.notes.map((item, idx) => {
                        return <div className="general-text" key={idx}>{item}</div>;
                      })}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="orderCheckbox"
                          checked={outOfBuilding}
                          onChange={handleOutOfBuildingChange}
                        />
                      }
                      label={<span className="general-text">Out of Building</span>}
                    />
                  </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="orderCheckbox"
                            checked={declinedMeal}
                            onChange={handleDeclinedMealChange}
                          />
                        }
                        label={<span className="general-text">Declined Meal</span>}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          {!outOfBuilding && !declinedMeal &&
            <Card sx={{marginBottom: "1rem"}}>
              <CardContent>
                <div style={{display:"grid",  gridTemplateColumns:"3fr 1fr", marginBottom: ".5rem"}}>
                  <Typography variant="h5">
                    Menu Items
                  </Typography>
                  <Button
                      variant="contained"
                      color="primary"
                      sx={{fontWeight: "bold"}}
                      onClick={() => setIsSelectAll((prev) => !prev)}
                  >
                    {isSelectAll ? "UNSELECT ALL" : "SELECT ALL"}
                  </Button>
                </div>
                <Table>
                  <TableBody>
                    {renderMenuItems()}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          }
          {!outOfBuilding && !declinedMeal &&
            <Card sx={{marginBottom: "1rem"}}>
              <CardContent>
                <Typography variant="h5" sx={{marginBottom: "1rem"}}>
                  Alternate Items
                </Typography>
                {renderAlternateItems()}
              </CardContent>
            </Card>
          }
          <Card sx={{marginBottom: "1rem"}}>
            <CardContent sx={{padding: "1rem 1.5rem"}}>
              <Typography variant="h5" sx={{marginLeft: "1.5rem", marginBottom: "1.5rem"}}>
                Note
              </Typography>
              <TextField
                id="textarea"
                className="noteTextarea"
                multiline
                rows={3}
                color="transparent"
                fullWidth
                value={noteText}
                // onChange fires too much to replace in object reference each time
                // (overhead concern)
                // store in state and replace before submit/hold
                onChange={(event) => {
                  if (!event.target.value) {
                    setShowAdditionalCharges(false);
                    setAddlCharges(0.00);
                  }

                  setNoteText(event.target.value);
                }}
                />
                <Grid container alignItems="center" justifyContent="space-between">
                  <Button 
                    disabled={!noteText}
                    variant="contained"
                    sx={{marginLeft: ".5rem", fontWeight: "bold"}}
                    onClick={() => {
                      setShowAdditionalCharges((prev) => !prev);
                      setAddlCharges(0.00);
                    }}
                  >
                    Additional Charges
                  </Button>

                  {showAdditionalCharges && (
                    <MoneyInput
                      className="addlCharge"
                      value={addlCharges}
                      // onChange fires too much to replace in object reference each time
                      // (overhead concern)
                      // store in state and replace before submit/hold
                      onChange={(event) => setAddlCharges(event.target.value)}
                    />
                  )}
                </Grid>
                {addlCharges > 100 && (
                  <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <p style={{color: "red", fontSize: ".75rem"}}>
                      Charge must be less than or equal to $100.00
                    </p>
                  </div>
                )}
            </CardContent>
          </Card>

          <Grid 
            container 
            justifyContent="center" 
            alignItems="center"
            sx={{width: "100% !important", flex: 1}}>
            { (canHold || residentId === 0) && !fromSP && !outOfBuilding && !declinedMeal && (
              <Button
                variant="contained"
                color="primary"
                sx={{fontWeight: "bold", marginRight: ".5rem", width: "calc(50% - 1rem)"}}
                onClick={() => handleSubmit("HOLD")}
              >
                Hold
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              sx={{fontWeight: "bold", width: (fromSP || !canHold || outOfBuilding || declinedMeal) ? "calc(100% - 2.5rem)" : "calc(50% - 1rem)"}}
              onClick={() => handleSubmit("SUBMIT")}
            >
              {fromSP ? "Submit / Reprint" : "Submit"}
            </Button>
          </Grid>
          { infoModalOpen &&
            <InfoModal title={modalTitle} open={infoModalOpen} modalText={modalText} handleClose={handleModalClose} />
          }
          { subRecipeModalOpen && 
            <SubRecipeModal 
            open={subRecipeModalOpen} 
            selectedRecipe={currentSubRecipe} 
            currentSubRecipes={selectedSubRecipes} 
            handleClose={handleSubRecipeClose} /> 
          }
          { confirmModalOpen &&
            <OrderConfirmModal 
              open={confirmModalOpen}
              orderData={orderInfo}
              confirmType={confirmType}
              selectedSubRecipes={selectedSubRecipes}
              handleClose={handleConfirmClose}
            />
          }
          <div id="printSection" style={{position: "absolute", top: "-9999px"}}> 
            {renderPrintContent()}
          </div>
        </div>
      )
    }
    </>
  )
}