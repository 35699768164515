import { isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import {
  closeGenericSnackbar,
  enqueueGenericSnackbar,
} from "../slices/snackbarSlice/GenericSnackbarSlice";

const mutationSnackbarMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    // Check if this action is a mutation from RTK Query
    const isRtkQueryMutationAction =
      action.type.startsWith("api/") && action.type.includes("executeMutation");

    function newSnackbar(key, message, variant, messageList, type) {
      return {
        key: key,
        message: message,
        type: type || "generic",
        options: {
          key: key,
          messagelist: messageList,
          variant: variant,
        },
      };
    }

    if (isRtkQueryMutationAction) {
      // const state = getState();
      const { arg, invalidatesTags } = action?.meta ?? { meta: {} };
      const { endpointName, originalArgs } = arg ?? {};

      let nameValue;

      if (originalArgs instanceof FormData) {
        // myObject is a FormData instance
        nameValue =
          originalArgs?.get("name") ??
          originalArgs?.get("title") ??
          originalArgs?.get("ingredient") ??
          originalArgs?.get("description") ??
          originalArgs?.get("fullname") ??
          originalArgs?.get("FullName") ??
          undefined;
      } else {
        nameValue =
          originalArgs?.name ??
          originalArgs?.title ??
          originalArgs?.ingredient ??
          originalArgs?.description ??
          originalArgs?.fullName ??
          originalArgs?.fullname ??
          undefined;
      }

      nameValue ??= invalidatesTags;
      nameValue ??= "";

      // Check the lifecycle of the RTK Query action
      if (isPending(action)) {
        let verb = "Processing";

        if (endpointName.includes("add")) {
          verb = "Adding";
        } else if (endpointName.includes("update")) {
          verb = "Updating";
        } else if (endpointName.includes("copy")) {
          verb = "Copying";
        } else if (endpointName.includes("print")) {
          verb = "Printing";
        }

        const notification = newSnackbar(
          endpointName + originalArgs?.id + "pending",
          `${verb} ${nameValue}...`,
          "default"
        );

        dispatch(enqueueGenericSnackbar({ notification }));
      } else if (isFulfilled(action) && !endpointName.includes("delete")) {
        let verb = "Processed";

        dispatch(
          closeGenericSnackbar({
            key: arg?.endpointName + arg?.originalArgs?.id + "pending",
          })
        );

        const { payload } = action || {};

        let payloadMessages = payload?.messages;

        if (payload?.data?.messages) payloadMessages = payload?.data?.messages;

        const payloadMessage =
          payloadMessages?.length > 0 ? payloadMessages[0] : null;

        if (payloadMessage) {
          const notification = newSnackbar(
            new Date().getTime() + Math.random(),
            payloadMessage,
            "success",
            payloadMessages?.length > 1 ? payloadMessages?.slice(1) : undefined
          );

          dispatch(
            enqueueGenericSnackbar({
              notification,
            })
          );
          return next(action);
        }

        if (payload?.data) {
          nameValue =
            payload.data.name ??
            payload.data.title ??
            payload.data.ingredient ??
            payload.data.description ??
            payload.data.fullName ??
            payload.data.fullname ??
            nameValue;
        } else {
          nameValue =
            payload?.name ??
            payload?.title ??
            payload?.ingredient ??
            payload?.description ??
            payload?.fullName ??
            payload?.fullname ??
            nameValue;
        }

        if (endpointName.includes("delete")) {
          verb = "Deleted";
        } else if (endpointName.includes("add")) {
          verb = "Added";
        } else if (endpointName.includes("update")) {
          verb = "Updated";
        } else if (endpointName.includes("copyVendor")) {
          verb = "Copied";
        } else if (endpointName.includes("copy")) {
          verb = "Copied as";
        } else if (endpointName.includes("print")) {
          verb = "Printed";
        }

        const message = `${verb} ${nameValue}.`;

        const notification = newSnackbar(
          new Date().getTime() + Math.random(),
          message,
          "success"
        );
        dispatch(enqueueGenericSnackbar({ notification }));
        return next(action);
      } else if (isRejected(action)) {
        let verb = "processing";
        dispatch(
          closeGenericSnackbar({
            key: arg?.endpointName + arg?.originalArgs?.id + "pending",
          })
        );

        const { payload } = action || {};

        let payloadMessages = payload?.messages;

        if (payload?.data?.messages) payloadMessages = payload?.data?.messages;

        const payloadMessage = payloadMessages ? payloadMessages[0] : null;

        if (payloadMessage) {
          const notification = newSnackbar(
            new Date().getTime() + Math.random(),
            payloadMessage,
            "error",
            payloadMessages?.length > 1 ? payloadMessages?.slice(1) : null
          );

          dispatch(
            enqueueGenericSnackbar({
              notification,
            })
          );
          return next(action);
        }

        if (endpointName.includes("delete")) {
          verb = "deleting";
        } else if (endpointName.includes("add")) {
          verb = "adding";
        } else if (endpointName.includes("update")) {
          verb = "updating";
        } else if (endpointName.includes("copy")) {
          verb = "copying";
        } else if (endpointName.includes("print")) {
          verb = "printing";
        }

        let errorMessage =
          payload?.data?.messages || payload?.messages || payload?.data?.title;
        if (!errorMessage) {
          if (payload?.status === 401) {
            errorMessage = "Unauthorized";
          } else if (payload?.status === 403) {
            errorMessage = "Forbidden";
          }
        }

        const notification = newSnackbar(
          new Date().getTime() + Math.random(),
          `Error while ${verb} ${nameValue}:  ${payload?.status} ${errorMessage}`,
          "error"
        );

        dispatch(enqueueGenericSnackbar({ notification }));
      }
    }

    return next(action);
  };

export default mutationSnackbarMiddleware;
