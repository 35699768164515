import { Card, CardContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetPendingMealsQuery } from "store/apis/MealsApi";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "components/shared/deleteModal/DeleteModal";

export default function PendingMeals() {

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedForDeleteId, setSelectedForDeleteId] = useState(null);

  const { 
    data: queuedOrders,
    isSuccess: queuedSuccess
  } = useGetPendingMealsQuery(
    {refetchOnMountOrArgChange: true}
  );

  // just trigger re-render when list was invalidated from delete here or add from parent
  useEffect(() => {}, [queuedSuccess]); 

  const renderQueuedOrders = () => {
    return queuedOrders?.map((order) => {
      const diningDate = new Date(order.diningDate);
      return (
        <TableRow key={order.id}>
          <TableCell className="tableCell">{diningDate.toDateString()} {diningDate.toLocaleTimeString('en-US', { hour12: false, timeStyle: "short" })}</TableCell>
          <TableCell className="tableCell">{order.mealName}</TableCell>
          <TableCell className="tableCell">{order.menuName}</TableCell>
          <TableCell className="tableCell">{order.day}</TableCell>
          <TableCell className="tableCell">{order.seasonName}</TableCell>
          <TableCell className="tableCell">{order.isActive ? 'Active' : 
              <IconButton onClick={() => {
                setSelectedForDeleteId(order.id);
                setIsDeleteOpen(true);
              }}>
                <DeleteIcon />
              </IconButton>  
            }
          </TableCell>
        </TableRow>
      );
    });
  }

  const afterDelete = () => {
    setSelectedForDeleteId(null);
    setIsDeleteOpen(false);
  }

  return (
    <>
      <Card sx={{margin: "1rem"}}>
        <CardContent>
          <Typography variant="h5">Queued Orders</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">Dining Date</TableCell>
                <TableCell className="tableCell">Meal</TableCell>
                <TableCell className="tableCell">Menu</TableCell>
                <TableCell className="tableCell">Day</TableCell>
                <TableCell className="tableCell">Season</TableCell>
                <TableCell className="tableCell"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderQueuedOrders()}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      {isDeleteOpen && 
        <DeleteModal 
        id={selectedForDeleteId}
        open={isDeleteOpen} 
        entityName={"this meal"}
        apiPath={"deleteMeal"}
        isafterSuccessFunction={true}
        afterSuccessFunction={afterDelete}
        close={() => setIsDeleteOpen(() => false)}
        />
      }
    </>
  );
}