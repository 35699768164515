import { 
  Box, 
  Button, 
  Card, 
  Checkbox, 
  Dialog, 
  FormControlLabel, 
  MenuItem, 
  Select, 
  Slide, 
  Table, 
  TableBody, 
  TableCell, 
  TableRow, 
  Typography 
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { forwardRef, useEffect, useState } from "react";
import "./SubRecipeModal.css";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function SubRecipeModal({open, selectedRecipe, currentSubRecipes = [], handleClose}) {
  const [selectedSubRecipes, setSelectedSubRecipes] = useState([]);

  useEffect(() => {
    // we only want the ones pertaining to the loaded item's subs
    setSelectedSubRecipes(currentSubRecipes.filter((sub) => sub.parentRecipeId === selectedRecipe.recipeId));
  }, [currentSubRecipes])

  const renderQuantity = (maxQuantity) => {
    const sizes = [];
    const servingSizes = [];

    for (let i = 0; i <= maxQuantity; i++) {
      servingSizes.push(i);
    }

    servingSizes.forEach((size) => {
      sizes.push(
        <MenuItem key={size} value={size}>
          <span className="general-text">{size}</span>
        </MenuItem>
      );
    });

    return sizes;
  }

  const selectQuantity = (item, value) => {
    updateSelectedList(item, !!value, value);
  }

  const handleSubRecipeChange = (item, checked, value) => {
    updateSelectedList(item, checked, value || 1)
  }

  const updateSelectedList = (item, checked, value) => {
    if (checked) {
      setSelectedSubRecipes((prev) => [...prev.filter((old) => old.recipeId !== item.recipeId), 
        {
          parentRecipeId: item.parentRecipeId,
          quantity: value,
          recipeId: item.recipeId,
          recipeName: item.recipeName,
          isAlternateItem: item.isAlternateItem
        }
      ]);
    } else {
      setSelectedSubRecipes((prev) => [...prev.filter((old) => old.recipeId !== item.recipeId)]);
    }
  }

  const renderTableItems = (recipe) => {
    const subMenuItems = [];
    const tempSubList = recipe.subRecipeList?.map((sub) => {
      return {
        parentRecipeId: sub.parentRecipeId,
        quantity: sub.quantity,
        recipeId: sub.recipeId,
        recipeName: sub.recipeName,
        isAlternateItem: false
      }
    });

    tempSubList?.sort((a, b) => a?.recipeId - b?.recipeId);

    tempSubList.forEach((sub) => {
      let flag = false;

      const foundSubRecipe = selectedSubRecipes?.find((selected) => selected?.recipeId === sub.recipeId
        && selected?.isAlternateItem === recipe.isAlternateRecipe && selected?.parentRecipeId === recipe.recipeId);
      const selectedQty = foundSubRecipe?.quantity || 0;

      sub.isAlternateItem = recipe.isAlternateRecipe;

      if (foundSubRecipe) { flag = true; }

      subMenuItems.push(
        <TableRow key={sub.recipeId} className="toggleRow" sx={{backgroundColor: flag ? "#46b9bc" : "white", display: "grid", gridTemplateColumns: "4fr 1fr", width: "100%"}}>
          <TableCell sx={{paddingRight: "0"}}>
            <FormControlLabel
              control={<Checkbox 
                id="subCheckbox"
                checked={flag} 
                className="general-text"
                onClick={() => {
                  handleSubRecipeChange(sub ,!flag, selectedQty);
                }} />}
                label ={<span className="general-text">{sub.recipeName}</span>}     
            />
            {/* CHECKBOX HERE */}
          </TableCell>
          <TableCell sx={{paddingLeft: "0", marginLeft: "auto !important"}}>
            <Select
              variant="standard"
              sx={{marginTop: ".25rem"}}
              disabled={!flag}
              value={selectedQty}
              onChange={(event) => selectQuantity(sub, event.target.value)}
              onClick={(e) => e.stopPropagation()}
            >
              {renderQuantity(recipe?.maxNumberOfServings || 5)}
            </Select>
          </TableCell>
        </TableRow>
      )
    });
    
    return subMenuItems;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      >
        <Box sx={{padding: "1.5rem", display: "flex", flexWrap: "wrap", flex: "1 0 100%"}}>
          <Card sx={{padding: "1.5rem"}}>
            <Typography variant="h5" sx={{marginBottom: "1.5rem"}}>{selectedRecipe?.recipeName}</Typography>
            <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column">
              <Table>
                <TableBody>
                  {!!selectedRecipe?.subRecipeList && renderTableItems(selectedRecipe)}
                </TableBody>
              </Table>
            </Grid>
          </Card>
        </Box>
        <Button 
          sx={{width: "fit-content", alignSelf: "center", fontWeight: "bold", marginBottom: "1rem"}} 
          variant="contained" 
          onClick={() => handleClose(selectedRecipe.recipeId, selectedSubRecipes)}
        >
          Ok
        </Button>
    </Dialog>
  )
}