import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import React, { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmModal({ 
  title, 
  modalText, 
  headerVariant = "h5", 
  textVariant = "subtitle1", 
  textWeight = "normal",
  confirmText = "Yes",
  cancelText = "No",
  open, 
  handleClose }) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      >
      <Box component="div" flexWrap="wrap" sx={{padding: "2rem", display: "flex", flex: "1 0 100%", justifyContent: "center", maxWidth: "500px"}}>
        <Typography variant={headerVariant} textAlign="left" fontSize="1.5rem" fontWeight="bold" 
          sx={{ margin: "0 0 .5rem 1.5rem", display: "block", width: "100%"}}>
            {title ?? ""}
        </Typography>
        <Typography variant={textVariant} fontWeight={textWeight} textAlign="center" sx={{marginBottom: "2rem", width: "100%"}}>
          {modalText}
        </Typography>
        <Button sx={{width: "45%", justifySelf: "center", marginRight: ".5rem", fontWeight: "bold"}} variant="contained" onClick={() => handleClose(true)}>
          {confirmText}
        </Button>
        <Button sx={{width: "45%", justifySelf: "center", fontWeight: "bold"}} variant="contained" onClick={() => handleClose(false)}>
          {cancelText}
        </Button>
      </Box>
    </Dialog>
  )
}