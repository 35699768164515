import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { MENUS } = API_ENDPOINTS;

export const MenusApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMenu: builder.query({
      query: ({ residentId, setupId, isGuest, includeDiningAreas, guestResidentId, guestName, guestBillingTypeId = 0}) => 
        `/${MENUS}?${residentId > 0 ? `residentId=${residentId}&` : ''}${setupId > 0 ? `tableSideSetupId=${setupId}&`: ''}isGuest=${isGuest}&includeDiningAreas=${includeDiningAreas}&guestResidentId=${guestResidentId}${guestName.trim() ? `&guestName=${guestName}&` : "&"}guestBillingTypeId=${guestBillingTypeId}`.trim(),
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
        ? [
          {type: MENUS, id: response},
          MENUS
        ]
        : [MENUS];
      }
    }),
    getRecipeImageUrl: builder.query({
      query: (recipeId) =>  `${MENUS}/RecipeImage/${recipeId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response 
        ? [
          {type: MENUS, id: response},
          MENUS
        ]
        : [MENUS];
      }
    }),
  })
});

export const {
  useGetMenuQuery,
  useGetRecipeImageUrlQuery
} = MenusApi;