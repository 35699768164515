import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice/AuthSlice";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import genericSnackbarReducer from "./slices/snackbarSlice/GenericSnackbarSlice";
import userInfoReducer from "./slices/userSlice/UserSlice";
import orderReducer from "./slices/orderSlice/OrderSlice";
import appReducer from "./slices/appSlice/AppSlice";
import diningAreaReducer from "./slices/diningAreaSlice/DiningAreaSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import mutationSnackbarMiddleware from "./middlewares/mutationSnackbarMiddleware";
import { BaseApi } from "./apis/BaseApi";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth", "facilityId"],
};

const reducers = combineReducers({
  auth: authReducer,
  [BaseApi.reducerPath]: BaseApi.reducer,
  genericSnackbar: genericSnackbarReducer,
  userInfo: userInfoReducer,
  diningArea: diningAreaReducer,
  order: orderReducer,
  app: appReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(BaseApi.middleware)
      .concat(mutationSnackbarMiddleware),
});
