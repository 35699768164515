import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { msalInstance } from "../..";
import { loginRequest } from "../../authConfig";
import { API_ENDPOINTS } from "../store.constants";

const tags = Object.values(API_ENDPOINTS);

const baseQuery = fetchBaseQuery({
  // eslint-disable-next-line no-undef
  baseUrl: process.env.REACT_APP_BASE_URL,
  refetchOnReconnect: true,
  keepUnusedDataFor: 30,
  prepareHeaders: async (headers) => {
    const account = msalInstance.getActiveAccount();
    if (account) {
      const tokenResponse = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      headers.set("Authorization", `Bearer ${tokenResponse.accessToken}`);
    }

    return headers;
  },
  retry: (count, error) => {
    // Define a maximum number of retries
    const maxRetries = 3;

    // Check if the maximum retries have been reached
    if (count >= maxRetries) return false;

    // Check for network errors (no response status)
    if (!error.status) {
      // Retry for network errors
      return true;
    }

    // Exclude retries for specific status codes (e.g., access issues or server errors)
    const noRetryStatusCodes = [401, 403, 500, 404];
    if (noRetryStatusCodes.includes(error.status)) {
      return false;
    }

    // Optionally, add more conditions for retrying based on the action or error specifics

    // Default to not retry
    return false;
  },
});

const baseQueryAI = fetchBaseQuery({
  // eslint-disable-next-line no-undef
  baseUrl: process.env.REACT_APP_BASE_AI_URL,
  refetchOnReconnect: true,
  keepUnusedDataFor: 30,
  prepareHeaders: async (headers, { getState }) => {
    const account = getState().auth.impersonated
      ? getState().auth.impersonatedAccount
      : getState().auth.account;
    if (account) {
      const tokenResponse = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      headers.set("Authorization", `Bearer ${tokenResponse.accessToken}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  if (api !== null && api.endpoint !== null && api.endpoint.includes("Chat")) {
    return await baseQueryAI(args, api, extraOptions);
  }
  //ALL OTHERS
  else {
    return await baseQuery(args, api, extraOptions);
  }
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const BaseApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [...tags],
  endpoints: () => ({}),
});
