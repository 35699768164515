import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import "@fontsource/material-icons";
import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useGetCurrentUserQuery } from "../../store/apis/UserApis";
import useNotifier from "../../utils/useNotifier";
import { BaseApi } from "store/apis/BaseApi";
import { BaseMain } from "./styled/BaseMain";
import { DrawerHeader } from "./styled/DrawerHeader";
import useMsalAccount from "utils/useMsalAccount";
import MenuIcon from "@mui/icons-material/Menu";
import RefreshIcon from "@mui/icons-material/Refresh";
import SideDrawer from "./SideDrawer";
import { selectMealName } from "store/slices/orderSlice/OrderSlice";

export default function Base() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { userId } = useMsalAccount();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const currentMeal = useSelector(selectMealName);
  const currentLocation = useLocation();
  const showMealName = !!(currentLocation.pathname.includes('/takeorder/guest/order') || currentLocation.pathname.includes('/takeorder/residents/order'));

  // Refetch all active queries if the user is changed (using logonAs for example)
  useEffect(() => {
    dispatch(BaseApi.util.resetApiState());
  }, [userId]);

  useEffect(() => {}, [instance?.getActiveAccount()]);

  useNotifier();
  const { data: currentUserData } = useGetCurrentUserQuery(userId, {
    skip: !isAuthenticated || userId == null,
    refetchOnMountOrArgChange: true,
  });

  const toggleDrawer = (event, newOpenState) => {
    setOpen(newOpenState);
  }

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
      <CssBaseline />
      <AppBar id="appHeader" position="fixed" open={open} sx={{borderRadius: "10px 10px 0 0 !important"}}>
        <Toolbar sx={{width: "100%", display: "flex"}}>
          <IconButton 
            sx={{color: "#FFF", marginRight: ".25rem"}} aria-label="Menu"
            onClick={(event) => toggleDrawer(event, !open)}>
            <MenuIcon />
          </IconButton>
          <SideDrawer open={open} toggleDrawer={toggleDrawer} />
          {
            showMealName ? 
            <Typography 
              variant="h6" 
              marginX="auto"
              color="#FFF" 
              fontWeight="700"
              letterSpacing=".5px"
            >
              {currentMeal}
            </Typography> 
            : 
            <Typography 
              variant="h6" 
              component="div" 
              color="#FFF" 
              fontWeight="700"
              marginRight="auto"
            >
              DiningRD
            </Typography>
          }
          <IconButton 
            color="inherit"
            sx={{color: "#FFF"}} 
            aria-label="Refresh"
            onClick={() => window.location.reload()}>
            <RefreshIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <BaseMain>
        <DrawerHeader sx={{minHeight: "40px !important"}} />
        <Outlet context={currentUserData} />
      </BaseMain>
    </Box>
  );
}
