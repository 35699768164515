import { Box, Card, Skeleton, Typography } from "@mui/material";
import React from "react";
import { BaseButton } from "../BaseButton";
import { DMStyles } from "styles/Styles";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseContent.md)
 */

const BaseContent = ({
  headerText,
  backLink = -1,
  backText = "Back",
  backIcon = "arrow_back",
  children,
  transparent = false,
  disableBack = false,
  disableHeight = false,
  cardPadding = "8px",
  boxMargin,
  cardMarginTop = "10px",
  btnColor,
  cardOverFlow = true,
  headerTextLoading = false,
  icon = true,
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  let styleObject = {
    padding: cardPadding,
    marginTop: cardMarginTop,
    backgroundColor: transparent ? "transparent" : "white",
  };

  if (!disableHeight) {
    styleObject["flexGrow"] = 1;
    styleObject["display"] = "flex";
    styleObject["flexDirection"] = "column";
  }

  if (cardOverFlow) {
    styleObject["overflow"] = "auto";
  }

  if (!transparent) {
    styleObject["boxShadow"] = "none !important";
  }
  const backNavigation = () => navigate(-1);

  return (
    <Box
      id="base-content"
      height={"100%"}
      marginX={boxMargin || "8px"}
      marginY={boxMargin || 0}
      display={"flex"}
      flexDirection={"column"}
      marginTop={"-69px"}>
      <Box
        display={"flex"}
        alignContent={"center"}
        flexDirection={"row"}
        minHeight={"42px"}
        justifyContent={"flex-start"}
        alignItems={"center"}>
        {!disableBack && (
          <BaseButton
            colors={btnColor}
            To={backLink ? backLink : undefined}
            state={state}
            zIndex={2}
            text={backText}
            startIcon={
              icon && <span className="material-icons">{backIcon}</span>
            }
            onClick={!backLink ? backNavigation : undefined}
          />
        )}
        {headerTextLoading ? (
          <Skeleton
            sx={{
              marginLeft: disableBack ? "24px" : "8px",
            }}
            variant="text"
            width={200}
            height={"40px"}
          />
        ) : (
          <Typography
            fontSize={DMStyles.HeadingFontSize}
            fontWeight={"900"}
            width={!disableBack ? "40%" : null}
            marginLeft={disableBack ? "24px" : "8px"}
            variant="h1"
            component={"h1"}>
            {headerText}
          </Typography>
        )}
      </Box>
      <Card
        id="base-content-card"
        sx={{
          ...styleObject,
        }}>
        {children}
      </Card>
    </Box>
  );
};

export default BaseContent;
