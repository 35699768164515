import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import React, { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function InfoModal({ title, modalText, headerVariant = "h5", textVariant = "subtitle1", open, handleClose }) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      >
      <Box component="div" flexWrap="wrap" sx={{padding: "2rem", display: "flex", flex: "1 0 100%", justifyContent: "center"}}>
        <Typography variant={headerVariant} textAlign="center" fontSize="1.5rem" fontWeight="bold" sx={{ margin: "0 0 .5rem", display: "block", width: "100%"}}>
            {title ?? ""}
        </Typography>
        <Typography variant={textVariant} whiteSpace="pre" textAlign="center" sx={{marginBottom: "1rem", width: "100%"}}>
          {modalText}
        </Typography>
        <Button sx={{width: "fit-content", justifySelf: "center"}} variant="contained" onClick={handleClose}>
          Ok
        </Button>
      </Box>
    </Dialog>
  )
}