import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permissions: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = [...action.payload]
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPermissions } = appSlice.actions;

export const selectPermissions = (state) => state?.app?.permissions;

export default appSlice.reducer;
