import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  facilityDiningAreas: null,
  lastDiningArea: {
    diningAreaId: null,
    diningAreaName: ""
  },
}

export const DiningAreaSlice = createSlice({
  name: "diningArea",
  initialState,
  reducers: {
    setFacilityDiningAreas: (state, action) => {
      state.facilityDiningAreas = {...action.payload};
    },
    setLastDiningArea: (state, action) => {
      state.lastDiningArea = {...action.payload};
    }
  }
})

export const { setFacilityDiningAreas, setLastDiningArea } = DiningAreaSlice.actions;
export const selectFacilityDiningAreas = (state) => state?.diningArea?.facilityDiningAreas;
export const selectLastDiningArea = (state) => state?.diningArea?.lastDiningArea;

export default DiningAreaSlice.reducer;