import { Box, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageModal({ sourceUrl, open, handleClose }) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth={false}
    >
      <Toolbar sx={{height: "2.75rem", justifyContent: "flex-end", minHeight: "2rem !important", padding: ".5rem .75rem !important"}}>
        <IconButton color="inherit" onClick={handleClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
      { sourceUrl ? (
            <Box component="div" sx={{padding: "0 2rem 2rem 2rem", display: "block", textAlign: "center", minWidth: "10rem", minHeight: "10rem"}}>
              <img style={{maxHeight: "75vh", width: "auto"}} src={sourceUrl} />
            </Box>
        )
        :
        (
          <Box component="div" 
            sx={{padding: "0 2rem 2rem 2rem", display: "flex", alignItems: "center", justifyContent: "center", width: "20rem", height: "15rem"}}>
            <Typography variant="h5">Image Not Found</Typography>
          </Box>
        )
      }
    </Dialog>
  )
}