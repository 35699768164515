import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { DINING_AREAS } = API_ENDPOINTS;

export const DiningAreasApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDiningAreas: builder.query({
      query: () => `/${DINING_AREAS}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
        ? [
            ...response.map(({ id }) => ({
              type: DINING_AREAS,
              id,
            })),
            DINING_AREAS
        ]
        : [DINING_AREAS];
      }
    }),
    getDiningAreasById: builder.query({
      query: (residentId, mealId) => `/${DINING_AREAS}/${residentId}/${mealId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response 
        ? [
          {type: DINING_AREAS, id: response.id},
          DINING_AREAS
        ]
        : [DINING_AREAS];
      }
    }),
  })
});

export const {
  useGetDiningAreasQuery,
  useGetDiningAreasByIdQuery
} = DiningAreasApi;